import { classed } from '@tw-classed/react'
import { cn } from '../../lib/utils.ts'

export const inputClassBase = cn(
  'caret-black outline-none enabled:hover:opacity-90 disabled:border-gray disabled:bg-gray disabled:text-white',
)
export const textAreaClass = cn(inputClassBase, 'rounded-6 p-8 ')
export const TextInput = classed.input(inputClassBase, {
  variants: {
    size: {
      little: 'h-28 rounded-4 px-8 text-14',
      large: 'h-44 rounded-6 px-16 text-20',
      extralarge: 'h-52 rounded-full px-16 text-20',
    },
    bg: {
      default: 'border-2 border-border-active',
      gray: 'border-2 border-purple bg-gray-light',
      'alert-light': 'bg-alert-light text-alert',
      'green-light': 'bg-green-light text-green-dark',
      white: 'bg-white',
    },
  },
  defaultVariants: {
    size: 'large',
    bg: 'default',
  },
})
export const TextArea = classed.textarea(textAreaClass)
