import { FormattedMessage, useIntl } from 'react-intl'
import Checkbox from '../../shared/ui/checkbox/checkbox.tsx'
import { ProfileTitle } from './ProfileComponents.tsx'
import { cn } from '../../shared/lib/utils.ts'
import { useState } from 'react'
import { AppMessageKeys } from '../../shared/translations/messages.ts'
import { TextInput } from '../../shared/ui/textInput/textInput.tsx'
import { Button } from '../../shared/ui/button/button.tsx'
import { useProfileStore } from './ProfileStore.ts'
import { useSnapshot } from 'valtio'
import { ProfileDrawer } from './ProfileComponents.tsx'
import { urls } from '../../shared/urls.ts'
import { ProfileSections } from '../../shared/data/profile.ts'
import { useNavigate } from 'react-router-dom'

export type CancelReason =
  | 'just_looking'
  | 'product_not_suitable'
  | 'too_expensive'
  | 'product_issues'
  | 'other_product'
  | 'chat_for_discount'

interface CancelOption {
  id: CancelReason
  text: AppMessageKeys
}

const cancelOptions: CancelOption[] = [
  { id: 'just_looking', text: 'user.cancelSubscription.reason1' },
  { id: 'product_not_suitable', text: 'user.cancelSubscription.reason2' },
  { id: 'too_expensive', text: 'user.cancelSubscription.reason3' },
  { id: 'product_issues', text: 'user.cancelSubscription.reason4' },
  { id: 'other_product', text: 'user.cancelSubscription.reason5' },
]

const WrappedInput = (props: {
  placeholder: string
  value: string
  onChange: (value: string) => void
}) => {
  return (
    <TextInput
      placeholder={props.placeholder}
      className="mt-18 h-[48px] w-full rounded-[26px] border-2 border-purple3 px-12 text-16 font-bold placeholder:text-16 placeholder:text-purple3"
      value={props.value}
      onChange={(e) => {
        props.onChange(e.target.value)
      }}
    />
  )
}

const CheckboxItem = ({
  item,
  isFirst,
  isLast,
  onChange,
  isActive,
  className,
}: {
  item: CancelOption
  isFirst: boolean
  isLast: boolean
  onChange: (id: CancelReason) => void
  isActive: boolean
  className?: string
}) => {
  return (
    <>
      <div
        className={cn(
          'flex min-h-[80px] cursor-pointer items-center gap-12 bg-gray-light px-14 py-16 font-nunito-7-semicondensed text-18 font-bold text-black transition-all duration-150',
          isFirst && 'rounded-t-18',
          isLast && 'rounded-b-18',
          isActive && 'bg-user-message',
          className,
        )}
        onClick={(e) => {
          e.stopPropagation()
          onChange(item.id)
        }}
      >
        <Checkbox
          key={item.id}
          checked={isActive}
          className="min-h-20 min-w-20"
          handler={(e) => {
            e.stopPropagation()
            onChange(item.id)
          }}
        />
        <p className="select-none">
          <FormattedMessage id={item.text} />
        </p>
      </div>
      {!isLast && <div className="h-1 w-full bg-divider" />}
    </>
  )
}

const CheckboxGroup = ({
  items,
  onChange,
  checkboxState,
}: {
  items: CancelOption[]
  onChange: (id: CancelReason) => void
  checkboxState: CancelReason[]
}) => {
  return (
    <div className="mt-24 flex w-full flex-col">
      {items.map((item, index) => (
        <CheckboxItem
          key={item.id}
          item={item}
          isFirst={index === 0}
          isLast={index === items.length - 1}
          onChange={() => {
            onChange(item.id)
          }}
          isActive={checkboxState.includes(item.id)}
        />
      ))}
    </div>
  )
}

const BottomBlock = () => {
  const store = useProfileStore()
  const handleCancelSubscription = () => {
    store.setShowCancelSubscription(true)
  }
  return (
    <div className="absolute bottom-0 flex h-[100px] w-full flex-col gap-18 bg-white px-16 mxs:fixed msm:fixed">
      <div className="h-1 w-full bg-coolgray350" />
      <Button
        bg="red-gradient"
        rounded="full"
        className="w-full"
        size="extralarge"
        onClick={handleCancelSubscription}
      >
        <FormattedMessage id="user.cancelSubscription" />
      </Button>
    </div>
  )
}

export const ProfileCancelSubscription = () => {
  const [checkboxState, setCheckboxState] = useState<CancelReason[]>([])
  const [comment, setComment] = useState('')
  const [contacts, setContacts] = useState('')
  const intl = useIntl()

  const handleSetInputValue = (
    value: string,
    variant: 'comment' | 'contacts',
  ) => {
    if (
      (variant === 'comment' && value.length >= 1000) ||
      (variant === 'contacts' && value.length >= 500)
    ) {
      return
    }
    if (variant === 'comment') {
      setComment(value)
    } else {
      setContacts(value)
    }
  }

  const handleCheckboxChange = (id: CancelReason) => {
    if (checkboxState.includes(id)) {
      setCheckboxState((prev) => prev.filter((item) => item !== id))
    } else {
      setCheckboxState((prev) => [...prev, id])
    }
  }

  return (
    <div className="flex flex-col items-center pb-[120px] msm:pb-20 mxl:pb-[20px] xl:relative ">
      <ProfileTitle title={<FormattedMessage id="user.cancelSubscription" />} />
      <h3 className="w-full text-center text-30 font-extrabold text-black">
        <FormattedMessage id="user.cancelSubscription.title" />
      </h3>
      <p className="mt-12 w-full text-center text-18 text-gray6">
        <FormattedMessage id="user.cancelSubscription.subtitle" />
      </p>
      <CheckboxGroup
        items={cancelOptions}
        onChange={handleCheckboxChange}
        checkboxState={checkboxState}
      />
      <WrappedInput
        placeholder={intl.formatMessage({
          id: 'user.cancelSubscription.placeholderComment',
        })}
        value={comment}
        onChange={(value) => {
          handleSetInputValue(value, 'comment')
        }}
      />
      <CheckboxItem
        item={{
          id: 'chat_for_discount',
          text: 'user.cancelSubscription.chatForDiscount',
        }}
        onChange={handleCheckboxChange}
        isFirst={true}
        isLast={true}
        isActive={checkboxState.includes('chat_for_discount')}
        className="mt-32 w-full text-[15px]"
      />
      <WrappedInput
        placeholder={intl.formatMessage({
          id: 'user.cancelSubscription.placeholderContacts',
        })}
        value={contacts}
        onChange={(value) => {
          handleSetInputValue(value, 'contacts')
        }}
      />
      <BottomBlock />
      <CancelSubscriptionDrawer
        comment={comment}
        contacts={contacts}
        checkboxState={checkboxState}
      />
    </div>
  )
}

function CancelSubscriptionDrawer({
  comment,
  contacts,
  checkboxState,
}: {
  comment: string
  contacts: string
  checkboxState: CancelReason[]
}) {
  const store = useProfileStore()
  const state = useSnapshot(store.state)
  const navigate = useNavigate()

  const handleUnsubscribe = () => {
    void store.unsubscribe({
      reason: checkboxState,
      comment,
      contacts,
    })
    navigate(urls.profile(ProfileSections.ManageSubscription), {
      replace: true,
    })
  }
  return (
    <ProfileDrawer
      title={<FormattedMessage id="user.cancelSubscription?" />}
      open={state.showCancelSubscription}
    >
      <div className="flex flex-col gap-16">
        <div>
          <FormattedMessage id="user.areYouSureCancel" />
        </div>

        <Button
          rounded="full"
          size="large"
          bg="red-gradient"
          onClick={() => {
            handleUnsubscribe()
            navigate(urls.profile(ProfileSections.ManageSubscription), {
              replace: true,
            })
          }}
        >
          <FormattedMessage id="yes" />
        </Button>

        <Button
          bg="transparent"
          size="large"
          onClick={() => {
            store.setShowCancelSubscription(false)
          }}
        >
          <FormattedMessage id="cancel" />
        </Button>
      </div>
    </ProfileDrawer>
  )
}
