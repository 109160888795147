import { AnimatePresence } from 'framer-motion'
import { FormattedMessage } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import { urls } from '../../shared/urls.ts'
import { useIsMobile } from '../../shared/lib/hooks.ts'
import { HintModal, HintWrapper } from './modal.tsx'

export const LearnProfileHint = ({ handler }: { handler: () => void }) => {
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  return (
    <HintWrapper
      idFocusElement="navbar.lessons"
      onFocusElementClick={() => {
        isMobile ? navigate(urls.practice) : {}
      }}
    >
      <AnimatePresence mode="wait">
        <ModalLessonFinishHint handler={handler} key="vocabModal" />
      </AnimatePresence>
    </HintWrapper>
  )
}

const ModalLessonFinishHint = ({ handler }: { handler: () => void }) => {
  const location = useLocation()
  const isLessonPage = location.pathname === '/learning-profile'

  return (
    <HintModal
      imageSrc="/images/EdmanHeaderOnboarding.png"
      title={
        <FormattedMessage
          id="LearninProfileFinishHintHeader"
          values={{ br: <br /> }}
        />
      }
      text={
        <FormattedMessage
          id="LearninProfileFinishHintText"
          values={{ br: <br /> }}
        />
      }
      buttonText={<FormattedMessage id={isLessonPage ? 'Continue' : 'ok'} />}
      onButtonClick={handler}
      closeButton={isLessonPage}
      bottomArrow={isLessonPage}
    />
  )
}
