import { proxy } from 'valtio'
import {
  AuthApi,
  MarkData,
  MarketingEvents,
  OnbEventSuffix,
} from '../../shared/api/authApi.ts'
import { HttpClient } from '../../shared/api/httpClient.ts'
import { nilMap, objectFilter } from '../../shared/lib/utils.ts'
import { AppLocalStorage } from '../../shared/lib/appLocalStorage.ts'
import { setLoading } from '../../shared/lib/storeUtils.ts'
import { getEdmanUserUUID } from './getEdmanUserUUID.ts'
import { isWebDomain } from '../../shared/urls.ts'
import { AppStore } from '../whatisnew/appStore.ts'
import { AppSessionStorage } from '../../shared/lib/appSessionStorage.ts'
import { LangLevels } from '../../shared/data/onboardingSteps.ts'
import { ChatApi, Student } from '../../shared/api/chatApi.ts'

const anonUserLogin = 'anon'

interface State {
  user?: {
    user_id: number
    access: string
    refresh: string
    login: string
    account_confirmed: boolean
  }
  isAnon: boolean
  isAnonOrLogout: boolean
  isLogin: boolean
  isActiveSubscribe: boolean
  isActiveStudent: boolean
  isPayer: boolean
  loading: boolean
  termsAgree: boolean
  recurrent: boolean
  autoPay: boolean
  confirmError: boolean
}
export class AuthStore {
  state

  constructor(
    private httpClient: HttpClient,
    private authApi: AuthApi,
    private chatApi: ChatApi,
    private appStorage: AppLocalStorage,
    private appStore: AppStore,
    private appSessionStorage: AppSessionStorage,
  ) {
    this.state = proxy<State>({
      confirmError: false,
      loading: false,
      get isAnon() {
        return this.user?.login === anonUserLogin
      },
      get isLogin() {
        return this.user != undefined
      },
      get isAnonOrLogout(): boolean {
        return this.isAnon || !this.isLogin
      },
      isActiveSubscribe: true,
      isActiveStudent: false,
      get isPayer(): boolean {
        return !!this.user && !this.isActiveStudent
      },
      termsAgree: false,
      autoPay: false,
      recurrent: false,
    })
    this.loadUser()
    this.httpClient.onRefreshTokens = (tokens) => {
      if (this.state.user) {
        if (tokens) {
          this.updateUser({
            ...this.state.user,
            access: tokens.access,
            refresh: tokens.refresh,
          })
        } else {
          this.logoutAndRefresh()
        }
      }
    }
  }

  setTermsAgree(termsAgree: boolean) {
    this.state.termsAgree = termsAgree
  }

  setRecurrent(value: boolean) {
    this.state.recurrent = value
  }

  setAutopay(value: boolean) {
    this.state.autoPay = value
  }

  setLogin(value: string) {
    if (this.state.user) {
      this.updateUser({ ...this.state.user, login: value })
    }
  }

  setSubscribeState(state: boolean) {
    this.state.isActiveSubscribe = state
  }

  setActiveStudent(state: boolean) {
    this.state.isActiveStudent = state
  }

  getActiveStudent() {
    return this.state.isActiveStudent
  }

  async getStudent() {
    const student: Student = await this.chatApi.getAccount()
    this.setActiveStudent(student.active)
    return student
  }

  updateUser(user: State['user'] | undefined) {
    this.state.user = user
    this.appStorage.set('user', user)
    this.httpClient.updateToken(
      nilMap(user, (x) => ({ access: x.access, refresh: x.refresh })),
    )
  }

  loadUser() {
    this.updateUser(this.appStorage.get('user') ?? undefined)
  }

  clearOnboardingInfo() {
    this.appSessionStorage.remove('onboarding')
    this.appSessionStorage.remove('level')
  }

  clearRouteHistory() {
    this.appStorage.remove('lastRoute')
  }

  isLogin() {
    return this.state.isLogin
  }

  isPayer() {
    return this.state.isPayer
  }

  isConfirm() {
    return this.state.user?.account_confirmed ?? false
  }

  async registration(login: string, password: string) {
    const markData = this.appStorage.get('markData')
    const language = this.appStore.getInitialLanguage()
    const { sms_confirm, ...user } = await setLoading(this.state, () =>
      this.authApi.register(
        login,
        password,
        markData,
        getEdmanUserUUID(),
        language,
      ),
    )
    const newUser = { ...user, login, account_confirmed: !sms_confirm }
    this.clearRouteHistory()
    this.updateUser(newUser)
    this.appStore.setLanguage(language)
    return newUser
  }

  async registerAnon(level: LangLevels) {
    this.logout()
    const { sms_confirm, ...user } = await setLoading(this.state, () =>
      this.authApi.register_anon(level),
    )
    const newUser = {
      ...user,
      login: anonUserLogin,
      account_confirmed: !sms_confirm,
    }
    this.updateUser(newUser)
  }

  deleteAnon() {
    if (this.state.isAnon) {
      void this.authApi.delete_anon()
      this.logout()
    }
  }

  async login(login: string, password: string) {
    const user = await setLoading(this.state, () =>
      this.authApi.login(login, password),
    )
    this.updateUser({ ...user, login })
  }

  async confirmPhone(code: string) {
    try {
      const result = await this.authApi.confirm_phone(code)
      const isSuccess = result.status == 'success'
      if (isSuccess && this.state.user) {
        this.updateUser({ ...this.state.user, account_confirmed: true })
      } else {
        this.state.confirmError = true
      }
      return isSuccess
    } catch {
      this.state.confirmError = true
      return false
    }
  }

  async resendCode() {
    await this.authApi.resend_code()
  }

  async getClientId() {
    try {
      return await new Promise<string | null>((resolve) => {
        if (window.ym) {
          window.ym(96670398, 'getClientID', function (clientID) {
            resolve(clientID)
          })
        } else {
          resolve(null)
        }
      })
    } catch {
      return null
    }
  }

  async getMarkData(): Promise<MarkData> {
    const params = new URLSearchParams(document.location.search)
    //  const referrer_domain = updateReferrer ? document.referrer : null
    const referrer_domain = params.get('referrer_domain')
    const utm_source = params.get('utm_source')
    const utm_content = params.get('utm_content')
    const utm_medium = params.get('utm_medium')
    const utm_campaign = params.get('utm_campaign')
    const utm_term = params.get('utm_term')
    const utm_placement = params.get('utm_placement')
    const clientID = await this.getClientId()
    return {
      utm_source,
      utm_content,
      referrer_domain,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_placement,
      clientID,
    }
  }

  async saveMarkData() {
    const oldMarkData = this.appStorage.get('markData')
    // if (oldMarkData && updateReferrer) {
    //   oldMarkData.referrer_domain = null
    // }
    const newMarkData = await this.getMarkData()
    const markData: MarkData = objectFilter(
      newMarkData as never,
      (k) => !!k,
    ) as never
    const updateMarkData = { ...oldMarkData, ...markData }
    this.appStorage.set('markData', updateMarkData)
  }

  logout() {
    this.updateUser(undefined)
    this.clearRouteHistory()
  }

  logoutAndRefresh() {
    this.updateUser(undefined)
    this.clearOnboardingInfo()
    this.clearRouteHistory()
    window.location.reload()
  }

  trackEvent(name: MarketingEvents) {
    void this.authApi.track_event(
      name,
      this.state.user?.user_id ?? null,
      getEdmanUserUUID(),
    )
  }

  trackOnbMetrika(event: OnbEventSuffix) {
    // const prefix = isNoDemoDomain ? 'onb_' : 'onbdemo_'
    const prefix = 'onb_'
    this.trackMetrika(`${prefix}${event}`)
  }

  trackMetrika(name: MarketingEvents) {
    if (!isWebDomain && name.startsWith('demo2_')) {
      return
    }
    try {
      if (!window.dataLayer) {
        window.dataLayer = []
      }
      window.dataLayer.push({ gaEventCategory: 'demo', gaEventAction: name })

      if (!window.ym) {
        return
      }
      window.ym(96670398, 'reachGoal', name, {
        userId: this.state.user?.user_id,
      })
    } catch {}
  }

  async recoveryPassword(email: string) {
    try {
      await this.authApi.reset_password(email)
      return 'success'
    } catch (e) {
      console.log(e)
      return 'error'
    }
  }
}
