import { useSnapshot } from 'valtio'
import {
  MatchWordsStore,
  TextOrSound,
  WordWithMeaning,
} from './matchWordsStore.ts'
import { useLazyRef } from '../../../shared/lib/hooks.ts'
import {
  ExerciseButton,
  ExerciseContainer,
  ExerciseTitle,
} from '../exerciseComponents.tsx'
import React from 'react'
import { useAppModule } from '../../appContext.ts'
import { Icon } from '../../../shared/ui/icon/icon.tsx'
import { WaveformProgressBar } from '../waveformProgressBar.tsx'
import { TaskSlider } from '../taskSlider/TaskSlider.tsx'
import { FormattedMessage } from 'react-intl'
import { Payload } from '../../../shared/lib/utils.ts'
import { ViewMode } from '../../../shared/api/chatApi.ts'

interface MatchWordProps {
  words: readonly WordWithMeaning[]
  completed: boolean
  onComplete: (
    result: string,
    correctAnswersCount: number,
    payload: Payload[],
    wrongAnswersCount: number,
    wrongAnswers: Record<string, number>,
  ) => void
  viewMode?: ViewMode
}

export const MatchWords = (props: MatchWordProps) => {
  const { speechService, answerStatusStore } = useAppModule()
  const viewMode = props.viewMode ?? 'full'
  const store = useLazyRef(
    () =>
      new MatchWordsStore(
        props.words,
        speechService,
        answerStatusStore,
        props.onComplete,
        viewMode,
      ),
  ).current

  const state = useSnapshot(store.state)

  return (
    <ExerciseContainer>
      <TaskSlider id={0} isLast={false}>
        <ExerciseTitle
          className="mb-60"
          title={<FormattedMessage id="exercise.tapPairs" />}
        />
        <div className="grid grid-cols-2 gap-28">
          <div className="flex flex-col gap-16">
            {state.words.map((x, i) => (
              <MatchButton
                index={i}
                store={store}
                key={i}
                word={x}
                isAnswer={false}
              />
            ))}
          </div>
          <div className="flex flex-col gap-16">
            {state.meanings.map((x, i) => (
              <MatchButton
                index={i}
                store={store}
                key={i}
                word={x}
                isAnswer={true}
              />
            ))}
          </div>
        </div>
      </TaskSlider>
    </ExerciseContainer>
  )
}

function SoundContent() {
  return (
    <div className="flex items-center justify-center gap-16">
      <Icon className="shrink-0 text-main-light" iconName="unmute" />{' '}
      <WaveformProgressBar small={true} progress={1} />
    </div>
  )
}

function MatchButton(props: {
  index: number
  store: MatchWordsStore
  word: TextOrSound
  isAnswer: boolean
}) {
  const state = useSnapshot(props.store.state)
  const status = state.buttonStatuses[props.index][props.isAnswer ? 1 : 0]
  const content = typeof props.word == 'string' ? props.word : <SoundContent />
  return (
    <ExerciseButton
      isCorrect={status}
      onClick={() => {
        props.store.select(props.index, props.isAnswer)
      }}
    >
      {content}
    </ExerciseButton>
  )
}
