export const LESSON_TYPE_ICONS: Record<string, string> = {
  Onboarding: '/images/program/lessons/rocket.png',
  'Warm Up': '/images/program/lessons/human.png',
  Grammar1: '/images/program/lessons/grammar.png',
  Reading: '/images/program/lessons/reading.png',
  Grammar2: '/images/program/lessons/grammar.png',
  Listening: '/images/program/lessons/listening.png',
  Vocabulary1: '/images/program/lessons/vocab.png',
  Vocabulary2: '/images/program/lessons/vocab.png',
  'Role play': '/images/program/lessons/roleplay.png',
  Challenges: '/images/program/lessons/roleplay.png',
}

export const LEVELS_MAP = {
  A1: { title: 'Elementary', subtitle: 'A1-A2' },
  A2: { title: 'Basic', subtitle: 'A2-B1' },
  B1: { title: 'Ambitious', subtitle: 'B1-B2' },
  B2: { title: 'Confident', subtitle: 'B2+' },
  C1: { title: 'Efficient', subtitle: 'B2-C1' },
  C2: { title: 'Fluent', subtitle: 'C1' },
}
