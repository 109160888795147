import Rive, { Layout, Fit } from '@rive-app/react-canvas'

export const LogoRive = () => {
  return (
    <Rive
      src="/animations/logotype_star.riv"
      className="h-[50px] w-[160px] px-12"
      layout={new Layout({ fit: Fit.FitWidth })}
    />
  )
}
