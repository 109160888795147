import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { cn } from '../../shared/lib/utils.ts'
import { TextArea } from '../../shared/ui/textInput/textInput.tsx'
import { motion } from 'framer-motion'
import { RateLessonStore } from './rateLessonStore.ts'
import { useSnapshot } from 'valtio'

function FormBlock(props: {
  title?: React.ReactNode
  children: React.ReactNode
}) {
  return (
    <motion.div
      className="mx-16 flex flex-col gap-14"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {props.title && (
        <div className="mb-4 text-center text-14">{props.title}</div>
      )}
      {props.children}
    </motion.div>
  )
}

const EXPERIENCE_OPTIONS = [
  { key: 'disappointedFace' },
  { key: 'slightlyFrowningFace' },
  { key: 'neutralFace' },
  { key: 'slightlySmilingFace' },
  { key: 'smilingFaceHeartEyes' },
]

const LEVEL_LESSON = [
  { key: 'thumbsDown' },
  { key: 'pinchingHand' },
  { key: 'thumbsUp' },
]

export const RateLesson = (props: {
  className?: string
  store: RateLessonStore
  id: string
}) => {
  const intl = useIntl()
  const state = useSnapshot(props.store.state)
  const isExperienceSelected = !!state.experienceRating

  return (
    <motion.div
      id={props.id}
      className={cn(
        'z-20 mb-[220px] flex flex-col gap-32  msm:w-full msm:max-w-[600px] sm:min-w-600 sm:max-w-800',
        props.className,
        isExperienceSelected ? 'mt-100' : 'mt-auto',
      )}
    >
      <FormBlock
        title={
          <p className="text-18 font-extrabold text-black">
            <FormattedMessage id="ratelesson.experienceRating" />
          </p>
        }
      >
        <div className="flex  justify-center gap-12">
          {EXPERIENCE_OPTIONS.map(({ key }, index) => (
            <img
              key={key}
              src={`/images/emojis/${key}.svg`}
              className={cn(state.experienceRating !== index && 'opacity-40')}
              onClick={() => {
                props.store.setExperienceRating(index)
              }}
            />
          ))}
        </div>
      </FormBlock>

      {isExperienceSelected && (
        <>
          <FormBlock
            title={
              <p className="text-18 font-extrabold text-black">
                <FormattedMessage id="ratelesson.suitableLevel" />
              </p>
            }
          >
            <div className="flex justify-center gap-12">
              {LEVEL_LESSON.map(({ key }, index) => (
                <img
                  key={key}
                  src={`/images/emojis/${key}.svg`}
                  className={cn(state.suitableLevel !== index && 'opacity-40')}
                  onClick={() => {
                    props.store.setSuitableLevel(index)
                  }}
                />
              ))}
            </div>
          </FormBlock>

          <FormBlock>
            <TextArea
              className="no-scrollbar h-52 max-h-52 min-h-52 w-full rounded-full border-2 border-purple3 px-20 pt-12 text-18 text-black"
              value={state.additionalFeedback}
              placeholder={intl.formatMessage({
                id: 'Anything youd like to add?',
              })}
              onChange={(e) => {
                props.store.setAdditionalFeedback(e.target.value)
              }}
            />
          </FormBlock>
        </>
      )}
    </motion.div>
  )
}
