import { motion } from 'framer-motion'

export const StarIcon = ({ className }: { className: string }) => {
  return (
    <motion.img
      src="/images/star.png"
      className={className}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
    />
  )
}

export const EmptyStarIcon = ({ className }: { className: string }) => {
  return (
    <motion.img
      src="/images/emptyStar.png"
      className={className}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
    />
  )
}
