import { TooltipRenderProps } from 'react-joyride'
import { classed } from '@tw-classed/react'

interface CustomStep {
  content: string
  imagePlace: 'top-right' | 'bottom' | 'top-left' | 'left'
  disableButton: boolean
  styles?: {
    tooltip?: React.CSSProperties
  }
}

const ClassedImg = classed('img', {
  base: 'absolute size-[110px] rounded-full object-cover bg-yellow5',
  variants: {
    imagePlace: {
      'top-right': 'top-[-92px] right-[-12px]',
      bottom: 'bottom-[-82px] left-[-18px]',
      'top-left': 'top-[-92px] left-[-12px]',
      right: 'right-[-80px] top-[0px]',
      left: 'left-[-90px] top-[0px]',
    },
  },
})

const JoyrideButton = classed('button', {
  base: 'absolute bottom-10 right-10 h-28 rounded-24 border-0 bg-gradient-to-b from-[#E2E6F3] to-[#B1BDDF] px-12 text-13 font-extrabold leading-none text-black outline-none',
})

const TooltipWrapper = classed('div', {
  base: 'relative rounded-16 bg-white p-16 shadow-tooltip pb-40 xl:pb-16 max-w-[calc(100vw-64px)] mx-auto',
})

const TooltipContent = classed('div', {
  base: 'text-16 font-semibold font-nunito',
})

export const JoyrideCustomTooltip = ({
  primaryProps,
  tooltipProps,
  step,
}: TooltipRenderProps) => {
  const customStep = step as unknown as CustomStep

  return (
    <TooltipWrapper
      {...tooltipProps}
      style={{
        ...customStep.styles?.tooltip,
      }}
    >
      <TooltipContent>{customStep.content}</TooltipContent>
      {customStep.content && !customStep.disableButton && (
        <JoyrideButton {...primaryProps}>{'Дальше'}</JoyrideButton>
      )}

      <ClassedImg
        imagePlace={customStep.imagePlace}
        src={'/images/new_edman.png'}
      />
    </TooltipWrapper>
  )
}
