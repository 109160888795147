import { motion } from 'framer-motion'
import { Button } from '../../shared/ui/button/button.tsx'
import { cn, useMount } from '../../shared/lib/utils.ts'
import { Icon } from '../../shared/ui/icon/icon.tsx'
import { useEffect, useState } from 'react'

export interface HintWrapperProps {
  children: React.ReactNode
  idFocusElement?: string
  isFocusElement?: boolean
  onFocusElementClick?: () => void
}

export const HintWrapper: React.FC<HintWrapperProps> = ({
  children,
  idFocusElement = '',
  isFocusElement = false,
  onFocusElementClick,
}) => {
  const [holeStyle, setHoleStyle] = useState<React.CSSProperties | null>(null)

  useEffect(() => {
    if (!isFocusElement) return
    const button = document.getElementById(idFocusElement)
    if (button) {
      const { top, left, height } = button.getBoundingClientRect()
      setHoleStyle({
        left: left - 10,
        top: top,
        width: height,
        height: height,
        boxShadow: '0 0 0 9999px rgba(0, 0, 0, 0.60)',
      })
    }
  }, [isFocusElement, idFocusElement])
  return (
    <motion.div
      className={cn(
        'fixed left-0 top-0 z-50 flex size-full max-h-dvh',
        isFocusElement ? '' : 'bg-black-80',
      )}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      {children}
      {isFocusElement && holeStyle && (
        <div
          style={holeStyle}
          className="pointer-events-auto absolute rounded-full bg-transparent"
          onClick={onFocusElementClick}
        />
      )}
    </motion.div>
  )
}

export interface ModalProps {
  imageSrc: string
  title: string | React.ReactNode
  text: string | JSX.Element | React.ReactNode
  buttonText: string | React.ReactNode
  onButtonClick: () => void
  closeButton?: boolean
  bottomArrow?: boolean
  className?: string
  classNameTitle?: string
  classNameText?: string
  idFocusElement?: string
}

export const HintModal: React.FC<ModalProps> = ({
  imageSrc,
  title,
  text,
  buttonText,
  onButtonClick,
  closeButton = true,
  bottomArrow = true,
  className,
  classNameTitle,
  classNameText,
  idFocusElement = '',
}) => {
  return (
    <motion.div
      className={cn(
        'relative z-50 m-auto flex h-fit w-[calc(100%-32px)] max-w-[600px] flex-col items-center rounded-12 bg-white pb-20 text-center',
        className,
      )}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <img src={imageSrc} className="rounded-t-12" />
      <h3
        className={cn(
          'font-nunito-7-semicondensed text-28 font-black text-black',
          classNameTitle,
        )}
      >
        {title}
      </h3>
      <p
        className={cn(
          'mx-28 mt-8 font-nunito-7-semicondensed text-16 font-normal',
          classNameText,
        )}
      >
        {text}
      </p>
      <Button
        size="extralarge"
        bg="blue-gradient-shadow-inset"
        className="mt-20 w-[calc(100%-32px)]"
        rounded="full"
        onClick={onButtonClick}
      >
        {buttonText}
      </Button>
      {closeButton && (
        <Button
          className="absolute right-12 top-12 size-28 border-none p-0 text-purple3"
          size="custom"
          rounded="full"
          bg="white"
          onClick={onButtonClick}
        >
          <Icon iconName="close" size="small" className="min-h-28 min-w-28" />
        </Button>
      )}
      {bottomArrow && <BottomArrow idFocusElement={idFocusElement} />}
    </motion.div>
  )
}

const BottomArrow = ({ idFocusElement = '' }: { idFocusElement: string }) => {
  const [leftOffset, setLeftOffset] = useState<number | null>(null)

  useMount(() => {
    const button = document.getElementById(idFocusElement)
    if (button) {
      const { left } = button.getBoundingClientRect()
      setLeftOffset(left)
    }
  })
  if (!leftOffset) return null
  return (
    <div
      className="absolute"
      style={{
        bottom: -14,
        left: leftOffset,
        width: 0,
        height: 0,
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderTop: '14px solid white',
      }}
    />
  )
}
