import { useNavigate } from 'react-router-dom'
import { ChatStore } from '../chats/chatStore.ts'
import { VocabLessonStore } from '../vocabLessons/vocabLessonStore.ts'
import { useSnapshot } from 'valtio'
import { useLazyRef } from '../../shared/lib/hooks.ts'
import { RateLessonStore } from '../rateLesson/rateLessonStore.ts'
import { cn, nilMap } from '../../shared/lib/utils.ts'
import { urls } from '../../shared/urls.ts'
import { AppMessageKeys } from '../../shared/translations/messages.ts'
import Rive, { Fit, Layout } from '@rive-app/react-canvas'
import { motion, AnimatePresence } from 'framer-motion'
import { FormattedMessage } from 'react-intl'
import { RateLesson } from '../rateLesson/rateLesson.tsx'
import { BottomButtons, IButtonProp } from '../../shared/ui/bottomButtons.tsx'
import {
  ADVANCED_LESSON_TAGS,
  VOCAB_LESSON_TAGS,
} from '../../shared/data/practice.ts'

export const LessonIsCompleted = ({
  store,
}: {
  store: ChatStore | VocabLessonStore
}) => {
  const navigate = useNavigate()
  // const store = useStores().chatStore
  const state = useSnapshot(store.state)
  const rateStore = useLazyRef(() => new RateLessonStore()).current
  const rateState = useSnapshot(rateStore.state)

  const suitableLevel = rateState.suitableLevel
  const experienceRating = rateState.experienceRating
  const addFeedback = rateState.additionalFeedback
  const isDemo = state.isDemo
  const isHelpLesson = state.isHelpLesson
  const isAdvancedLesson = ADVANCED_LESSON_TAGS.includes(state.programTag)
  const isVocabLesson = VOCAB_LESSON_TAGS.includes(state.programTag)

  const handleSubmit = async () => {
    void store.rateChat(
      nilMap(suitableLevel, (x) => x + 1),
      nilMap(experienceRating, (x) => x + 1),
      addFeedback,
    )

    if (isDemo) {
      navigate(urls.onboarding('generation'))
    } else {
      await store.goToLessonStat()
    }
  }
  const starsTextIds: Record<number, AppMessageKeys> = {
    1: 'finish.oneStar',
    2: 'finish.twoStars',
    3: 'finish.threeStars',
  }

  const repeatLessonProp = {
    handler: () => {
      isAdvancedLesson
        ? navigate(urls.practiceWords('select', state.programTag))
        : void store.newChat(state.programTag, true, state.lessonType)
    },
    textId: 'Repeat the lesson',
    bg: 'transparent',
    visible: !isHelpLesson,
  } as IButtonProp

  const firstButtonProps = {
    handler: () => {
      void (async () => {
        await handleSubmit()
      })()
    },
    textId: isHelpLesson ? 'Continue' : 'Finish the lesson',
    bg: 'black-gradient',
    visible: true,
    withIcon: true,
  } as IButtonProp

  const secondButtonProps =
    isVocabLesson || !state.nextProgramTag
      ? repeatLessonProp
      : ({
          handler: () => {
            state.nextProgramTag &&
              void store.newChat(state.nextProgramTag, true, state.lessonType)
          },
          textId: 'nextLesson',
          bg: 'gray-gradient',
          visible: !isHelpLesson,
        } as IButtonProp)

  const thirdButtonProps = repeatLessonProp

  return (
    <div className="fixed left-0 top-0 z-20 flex h-[100dvh] w-full flex-col items-center justify-between bg-blue6 backdrop-blur-20">
      <Rive
        className="pointer-events-none absolute z-40 size-full"
        src="/animations/confetti.riv"
        layout={
          new Layout({
            fit: Fit.Cover,
          })
        }
      />
      <AnimatePresence>
        {!rateState.experienceRating && (
          <motion.div
            id="lesson_start"
            className={cn('relative h-[calc(100dvh-120px)]')}
            initial={{ opacity: 0, height: 'auto' }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: '0' }}
            transition={{
              opacity: { duration: 0.15 },
              height: { duration: 0.3 },
            }}
          >
            <div
              className={cn(
                'mx-24 text-center text-48 font-extrabold text-black',
                isHelpLesson ? 'mt-80' : 'mt-40',
              )}
            >
              <FormattedMessage id="Lesson completed!" />
              <div className="mx-auto mt-32 flex justify-center">
                {!state.loading &&
                  [...Array(3).keys()].map((i) => {
                    if (!state.lessonRate) return null
                    return (
                      <motion.img
                        src={
                          i < state.lessonRate
                            ? '/images/star.png'
                            : '/images/emptyStar.png'
                        }
                        key={`star-${i}`}
                        className="size-72"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3 }}
                      />
                    )
                  })}
              </div>
              {!state.loading && (
                <p className="mt-24 text-14 font-bold text-gray4">
                  <FormattedMessage
                    id={
                      isDemo
                        ? 'finish.demo'
                        : state.lessonRate === null
                        ? 'EmptyId'
                        : starsTextIds[state.lessonRate]
                    }
                  />
                </p>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {!isHelpLesson && (
        <AnimatePresence>
          {' '}
          <RateLesson id="lesson_exp" store={rateStore} />
        </AnimatePresence>
      )}
      {!isVocabLesson && state.nextProgramTag ? (
        <BottomButtons
          firstButtonProps={firstButtonProps}
          secondButtonProps={secondButtonProps}
          thirdButtonProps={thirdButtonProps}
        />
      ) : (
        <BottomButtons
          firstButtonProps={firstButtonProps}
          secondButtonProps={secondButtonProps}
        />
      )}
      {isHelpLesson && (
        <p className="mx-32 mb-80 text-center font-nunito-7-condensed text-20">
          <FormattedMessage id="finish.firstStep" values={{ br: <br /> }} />
        </p>
      )}
    </div>
  )
}
