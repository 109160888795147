export enum ProfileSections {
  ManageAccount = 'manageAccount',
  ChangeOnboardingInfo = 'changeOnboardingInfo',
  ManageSubscription = 'manageSubscription',
  CancelSubscription = 'cancelSubscription',
  PostChangeSubscription = 'postChangeSubscription',
  Faq = 'faq',
  Support = 'support',
  Notifications = 'notifications',
  AppLanguage = 'appLanguage',
  About = 'about',
  Logout = 'logout',
}
