import { AppMessageKeys } from '../translations/messages.ts'

import { IconName } from '../ui/icon/icon.tsx'

export type SubscriptionSections = 'email' | 'paywall' | 'subs' | 'card-added'

export interface TrialInfoBlock {
  img: IconName
  imgBg: string
  title: AppMessageKeys
  desc: AppMessageKeys
  titleColor: string
  descColor: string
}

export const FAQ_LIST = [
  {
    question: 'subViewFaqQuestion0',
    answer: 'subViewFaqAnswer0',
  },
  {
    question: 'subViewFaqQuestion1',
    answer: 'subViewFaqAnswer1',
  },
  {
    question: 'subViewFaqQuestion2',
    answer: 'subViewFaqAnswer2',
  },
  {
    question: 'subViewFaqQuestion3',
    answer: 'subViewFaqAnswer3',
  },
  {
    question: 'subViewFaqQuestion4',
    answer: 'subViewFaqAnswer4',
  },
] as const

export interface IExpBlock {
  img: string
  alt: string
  title: AppMessageKeys
  desc: AppMessageKeys
}

export const EXP_BLOCKS = [
  {
    img: '/images/program/lessons/hands.png',
    title: 'subViewTitle1' as AppMessageKeys,
    desc: 'subViewDesc1' as AppMessageKeys,
    alt: 'hands',
  },
  {
    img: '/images/program/lessons/earth.png',
    title: 'subViewTitle2' as AppMessageKeys,
    desc: 'subViewDesc2' as AppMessageKeys,
    alt: 'earth',
  },
  {
    img: '/images/program/lessons/teacher.png',
    title: 'subViewTitle3' as AppMessageKeys,
    desc: 'subViewDesc3' as AppMessageKeys,
    alt: 'teacher',
  },
  {
    img: '/images/program/lessons/ninja.png',
    title: 'subViewTitle4' as AppMessageKeys,
    desc: 'subViewDesc4' as AppMessageKeys,
    alt: 'ninja',
  },
  {
    img: '/images/program/lessons/rocket-white-circle.png',
    title: 'subViewTitle5' as AppMessageKeys,
    desc: 'subViewDesc5' as AppMessageKeys,
    alt: 'rocket',
  },
]

export const REVIEWS_LIST = [
  {
    text: 'Тут мне особенно понравилось, что мистер Эдман ошибся, но признал свою ошибку, когда я указал на неё. Это показывает, что он открыт к диалогу и готов учиться!',
    name: 'Никита, разработчик',
  },
  {
    text: 'С Эдманом проще начать говорить на английском, так как нет страха ошибиться.',
    name: 'Юлия, маркетолог',
  },
  {
    text: 'Если возможно было изменять скорость речи ассистента, это было бы отлично. А так, практически тоже самое, что и занятие с живым преподавателем',
    name: 'Анна, юрист',
  },

  //   {
  //     text: 'Озвучка в уроке просто 🔥',
  //     name: 'Анна, юрист',
  //   },
  {
    text: 'наконецто я понял to be',
    name: 'Марат, учитель',
  },
  {
    text: 'Спасибо за игры в словарике, теперь в метро провожу время с пользой👍',
    name: 'Инна, психолог',
  },
]
