import { useLazyRef } from '../../shared/lib/hooks.ts'
import { useAppModule } from '../../features/appContext.ts'
import { useSnapshot } from 'valtio/react'
import { cn, isKeyInObject, useUnmount } from '../../shared/lib/utils.ts'
import { classed } from '@tw-classed/react'
import { useLocation } from 'react-router-dom'
import { AppHeaderNavigationButton } from '../../features/appHeader/appHeader.tsx'
import { LessonType } from '../../shared/api/chatApi.ts'
import {
  useVocabStore,
  VocabStoreContext,
  VocabView,
} from '../../features/vocabLessons/vocabLessonView.tsx'
import { motion } from 'framer-motion'
import { MainContainer } from '../../shared/ui/card/card.tsx'

export function VocabChat(props: { tag: string }) {
  const location = useLocation()
  const state = location.state as {
    recreateChat: boolean
    lessonType: LessonType
    userVocab: string[]
    isHelpVocabLesson: boolean
  } | null
  const recreateChat =
    state !== null && isKeyInObject('recreateChat', state)
      ? state.recreateChat
      : false

  const userVocab =
    state !== null && isKeyInObject('userVocab', state)
      ? { user_vocab: state.userVocab }
      : null

  const appModule = useAppModule()

  const vocabLessonStoreRef = useLazyRef(() => {
    const vocabLessonStore = appModule.vocabLessonStore()
    void vocabLessonStore.newChat(
      props.tag,
      recreateChat,
      'vocabulary',
      userVocab,
      state?.isHelpVocabLesson,
    )
    return {
      vocabLessonStore,
    }
  })

  //   const vocabLessonState = useSnapshot(
  //     vocabLessonStoreRef.current.vocabLessonStore.state,
  //   )
  //   const authState = useSnapshot(appModule.authStore.state)

  useUnmount(() => {
    appModule.speechService.dispose()
  })

  const progressBlock = <StageProgressBlockMobile />

  return (
    <VocabStoreContext.Provider value={vocabLessonStoreRef.current}>
      <div className={cn('flex h-dvh w-full')}>
        <MainContainer className="flex flex-col" enable={true}>
          {progressBlock}
          <VocabChatContainer data-testid="chat-page">
            <VocabView />
          </VocabChatContainer>
        </MainContainer>
      </div>
    </VocabStoreContext.Provider>
  )
}

function VocabProgressBar() {
  const store = useVocabStore().vocabLessonStore
  const state = useSnapshot(store.state)
  const exerciseCount = state.exerciseCount
  const currentIndex = state.currentIndex
  const currentTaskIndex = state.currentTaskIndex
  const isLessonCompleted = state.lessonCompleted
  const exercisesInfo = state.exercises

  if (exerciseCount === null) return null
  return (
    <div className="flex w-full gap-4">
      {Array.from({ length: exerciseCount }).map((_, outerIndex) => {
        const tasks_count = exercisesInfo
          ? exercisesInfo[outerIndex].exercise_count
          : 1
        const isActive = isLessonCompleted
          ? outerIndex < currentIndex
          : outerIndex + 1 < currentIndex

        if (tasks_count > 1) {
          return (
            <div
              key={`progress-main-bar-${outerIndex}`}
              className="flex w-full gap-2"
            >
              {Array.from({ length: tasks_count }).map((_, innerIndex) => {
                const isTaskCompleted = isActive
                  ? true
                  : innerIndex + outerIndex * tasks_count <
                    (currentIndex - 1) * tasks_count + currentTaskIndex
                return (
                  <ProgressBarElement
                    key={`progress-bar-${outerIndex}-${innerIndex}`}
                    isActive={isTaskCompleted}
                    animationDuration={0.25}
                  />
                )
              })}
            </div>
          )
        }
        return (
          <ProgressBarElement
            key={`progress-bar-${outerIndex}`}
            isActive={isActive}
            animationDuration={0.5}
          />
        )
      })}
    </div>
  )
}

const ProgressBarElement = ({
  isActive,
  animationDuration,
}: {
  isActive: boolean
  animationDuration: number
}) => {
  return (
    <motion.div className="h-6 w-full overflow-hidden rounded-full bg-blue8 ">
      <motion.div
        className="h-full bg-blue7"
        initial={{ width: 0 }}
        animate={{ width: isActive ? '100%' : '0%' }}
        transition={{ duration: animationDuration, ease: 'easeInOut' }}
      />
    </motion.div>
  )
}

function StageProgressBlockMobile() {
  const store = useVocabStore().vocabLessonStore
  const state = useSnapshot(store.state)
  const isLessonCompleted = state.lessonCompleted
  return (
    <div className="fixed left-0 top-0 z-20 flex h-100 w-full items-center bg-demo-mobile-header px-16 backdrop-blur-4 xl:static">
      <div className="flex items-center">
        {!isLessonCompleted && <AppHeaderNavigationButton />}
      </div>
      <div
        className={cn(
          'flex flex-1 gap-6 text-start text-18 font-bold',
          isLessonCompleted ? 'ml-24' : 'ml-16',
        )}
      >
        <VocabProgressBar />
      </div>
    </div>
  )
}

export const VocabChatContainer = classed.div(
  'flex w-full flex-1 justify-between gap-24 overflow-hidden bg-white sm:rounded-tl-8 sm:bg-white sm:px-36 sm:pt-36',
)
