import { Outlet } from 'react-router-dom'
import { Toasts } from '../features/toasts/toasts.tsx'
import { Menu } from '../features/rightmenu/menu.tsx'
import { useIsMobile } from '../shared/lib/hooks.ts'
import { cn } from '../shared/lib/utils.ts'
import { Navbar } from '../features/navbar/navbar.tsx'
import { MainContainer } from '../shared/ui/card/card.tsx'
import { useAppModule } from '../features/appContext.ts'
import { SubscribeButton } from '../features/subscribeButton/subscribeButton.tsx'

export function Layout(props: {
  withMenu: boolean
  withNavbar: boolean
  fullWidth?: boolean
  scroll?: boolean
  white?: boolean
  customContainer?: boolean
  mainContainer?: boolean
}) {
  const isMobile = useIsMobile()
  const authStore = useAppModule().authStore

  return (
    <div
      className={cn(
        'w-full [scrollbar-width:thin]',
        props.white ? 'bg-white xl:bg-bglight' : 'bg-light xl:bg-bglight',
      )}
    >
      <div className="relative flex min-h-dvh gap-40 xl:gap-0">
        {props.withNavbar && (isMobile ? <Navbar /> : <Menu />)}
        {props.withMenu && !isMobile && <Menu />}
        <MainContainer
          enable={!props.customContainer && props.withNavbar && !isMobile}
        >
          {!authStore.state.isActiveSubscribe && props.mainContainer && (
            <SubscribeButton />
          )}
          <Outlet />
        </MainContainer>
      </div>
      <Toasts />
    </div>
  )
}
