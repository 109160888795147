/* eslint-disable valtio/state-snapshot-rule */
import {
  ProgramContext,
  useProgramStore,
} from '../features/programs/programView.tsx'
import {
  useLazyRef,
  useLastStepSpotlightClick,
  useIsMobile,
} from '../shared/lib/hooks.ts'
import { useAppModule } from '../features/appContext.ts'
import { cn, sortBy, useMount } from '../shared/lib/utils.ts'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSnapshot } from 'valtio/react'
import {
  Drawer,
  DrawerBar,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from '../shared/ui/drawer/drawer.tsx'
import { Icon } from '../shared/ui/icon/icon.tsx'
import { ProgramItem } from '../shared/api/chatApi.ts'
import { Button, LinkButton } from '../shared/ui/button/button.tsx'
import { urls } from '../shared/urls.ts'
import { useLocation, useNavigate } from 'react-router-dom'
import { BottomButtons } from '../shared/ui/bottomButtons.tsx'
import { AppMessageKeys } from '../shared/translations/messages.ts'
import { TYPES_TIME_MAP, TYPES_WITH_SOUND } from '../shared/data/lessonsTags.ts'
import Joyride from 'react-joyride'
import { handleSetSpotlightHeight } from '../shared/lib/joyrideUtils.ts'
import {
  DESKTOP_PROGRAM_TOUR_STEPS,
  PROGRAM_TOUR_STEPS,
} from '../shared/data/joyride.tsx'
import { LESSON_TYPE_ICONS, LEVELS_MAP } from '../shared/data/programs.ts'
import { LearnProfileHint } from '../features/hints/lessonHint.tsx'
import { Dialog } from '../shared/ui/dialog/dialog.tsx'
import { Spinner } from '../shared/ui/spinner.tsx'
import { AnimatePresence, motion } from 'framer-motion'
import { classed } from '@tw-classed/react'
import { JoyrideCustomTooltip } from '../shared/ui/joyrideTooltip/JoyrideCustomTooltip.tsx'
import Rive from '@rive-app/react-canvas'

interface TopicItem {
  readonly program: string
  readonly items: readonly {
    readonly title: string
    readonly items: readonly ProgramItem[]
    readonly index: number
  }[]
}

export function ProgramPage2(props: { registration: boolean }) {
  const location = useLocation()
  const locationState = location.state as {
    showOnboardingHint: boolean
  } | null
  const appModule = useAppModule()
  const store = useLazyRef(() =>
    appModule.programStore(props.registration),
  ).current
  const state = useSnapshot(store.state)
  const [stepIndex, setStepIndex] = useState(0)
  const [showOnboardingHint, setShowOnboardingHint] = useState(
    locationState?.showOnboardingHint,
  )
  //   const isMarketingLearningProfileHint =
  //     state.hints?.marketingLearningProfileHint

  const isMobile = useIsMobile()
  const navigate = useNavigate()

  useMount(() => {
    void store.loadPrograms()
  })

  const handleOpenFirstLesson = () => {
    if (state.currentCycle?.items && state.currentCycle.items.length > 0) {
      store.endTour()
      const firstLesson = state.currentCycle.items[0]
      navigate(urls.lesson(firstLesson.tag))
      void store.deleteLessonsHint()
    }
  }

  useLastStepSpotlightClick({
    isLastStep: stepIndex === 3,
    onSpotlightClick: handleOpenFirstLesson,
  })

  const handleJoyrideCallback = ({ index }: { index: number }) => {
    setStepIndex(index)
    index === 2 && handleSetSpotlightHeight('units-0-header')
  }

  const hintLearningProfileOnboardingHandler = () => {
    setShowOnboardingHint(false)
  }
  if (state.renderLoading)
    return (
      <div className="flex h-screen  w-full items-center justify-center">
        <Spinner />
      </div>
    )

  return (
    <ProgramContext.Provider value={store}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="flex w-full flex-col overflow-hidden bg-white msm:pt-0 mxl:pt-100 xl:bg-bglight xl:px-40 xl:py-24"
      >
        <ProgramPageHeader />

        <div className="bg-white pt-16 xl:mt-28 xl:flex xl:gap-18  xl:bg-bglight xl:pt-0">
          <TopicsList />
          <DesktopLangLevel />
        </div>

        <Units />
        {/* {isMarketingLearningProfileHint && (
          <LearnProfileMarketingVPHint handler={hintHandler} />
        )} */}
        <Joyride
          steps={isMobile ? PROGRAM_TOUR_STEPS : DESKTOP_PROGRAM_TOUR_STEPS}
          callback={handleJoyrideCallback}
          run={state.shouldViewHint && !isMobile}
          continuous={true}
          showSkipButton={false}
          hideCloseButton={true}
          hideBackButton={true}
          showProgress={false}
          disableOverlayClose={true}
          spotlightPadding={isMobile ? 8 : 10}
          disableScrollParentFix={false}
          disableCloseOnEsc={true}
          disableScrolling={false}
          scrollOffset={110}
          locale={{
            next: 'Дальше',
            last: 'Дальше',
          }}
          styles={{
            spotlight: {
              borderRadius: 22,
            },
            tooltip: {
              borderRadius: 16,
              paddingBottom: 32,
              padding: 16,
            },
          }}
          spotlightClicks={true}
          tooltipComponent={JoyrideCustomTooltip}
        />
        {showOnboardingHint && (
          <LearnProfileHint handler={hintLearningProfileOnboardingHandler} />
        )}
      </motion.div>
    </ProgramContext.Provider>
  )
}

const DesktopLangLevelWrapper = classed.div(
  'hidden w-[282px] cursor-pointer select-none flex-col items-center rounded-24 bg-white text-black shadow-drop xl:flex',
)

function DesktopLangLevel() {
  const [isOpen, setIsOpen] = useState(false)
  const store = useProgramStore()
  const state = useSnapshot(store.state)
  const level = state.selectedLevel as keyof typeof LEVELS_MAP
  // eslint-disable-next-line
  const title = LEVELS_MAP[level]?.title

  return (
    <Dialog.Root
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open)
      }}
    >
      <Dialog.Trigger
        asChild
        onClick={() => {
          setIsOpen(true)
        }}
      >
        <DesktopLangLevelWrapper>
          <p className="mt-20 text-18 font-extrabold">
            <FormattedMessage id="englishLevel" />
          </p>
          <p className="mt-24 text-[58px] font-extrabold">{level}</p>
          <p className="mt-10 text-18 font-extrabold">
            {title ? <FormattedMessage id={title as AppMessageKeys} /> : null}
          </p>
          <p className="cursor-pointer text-12 font-bold text-blue5">
            <FormattedMessage id="changeCourse" />
          </p>
        </DesktopLangLevelWrapper>
      </Dialog.Trigger>
      <Dialog.Content className="bg-white pt-40">
        <LangLevelDialogContent setIsOpen={setIsOpen} />
      </Dialog.Content>
    </Dialog.Root>
  )
}

function ProgramPageHeader() {
  const isMobile = useIsMobile()
  return isMobile ? <ProgramPageHeaderMobile /> : <ProgramPageHeaderDesktop />
}

function ProgramPageHeaderDesktop() {
  const store = useProgramStore()
  const state = useSnapshot(store.state)
  return (
    <div className="flex h-fit w-full items-center justify-between">
      <h3 className="text-22 font-bold">
        <FormattedMessage id="welcomeBack" values={{ name: state.name }} />
      </h3>
      {/* <LangSwitcher darkBorder grayText /> */}
    </div>
  )
}

function ProgramPageHeaderMobile() {
  return (
    <div
      className={cn(
        'flex h-100 w-full items-center',
        'fixed left-0 top-0 z-10 bg-white xl:static',
      )}
    >
      <div className="flex w-full items-center justify-between pr-12">
        <div className="mx-auto" id="level-select-button">
          <LevelBottomSheet />
        </div>
      </div>
    </div>
  )
}

export const LessonSelectorElements = ({
  lessonTag,
  isCompleted,
}: {
  lessonTag: string
  isCompleted: boolean
}) => {
  const navigate = useNavigate()
  return (
    <div className="flex flex-col px-28 xl:px-0">
      <div className="text-22 font-bold">
        <FormattedMessage
          id={isCompleted ? 'Repeat the lesson?' : 'Continue the lesson?'}
        />
      </div>
      <div className="mt-14">
        <FormattedMessage
          id={isCompleted ? 'You already complete' : 'You are in progress'}
        />
      </div>
      <BottomButtons
        firstButtonProps={{
          handler: () => {
            navigate(urls.lesson(lessonTag), {
              state: { recreateChat: isCompleted },
            })
          },
          textId: isCompleted ? 'Repeat the lesson' : 'Continue the lesson',
          bg: 'blue-gradient',
          visible: true,
        }}
        secondButtonProps={{
          handler: () => {
            navigate(urls.lesson(lessonTag), {
              state: {
                recreateChat: !isCompleted,
                mode: isCompleted ? 'readonly' : 'full',
              },
            })
          },
          textId: isCompleted ? 'Lesson review' : 'Restart the lesson',
          bg: 'transparent',
          visible: true,
          withIcon: false,
        }}
        wrapperClassname="flex flex-col gap-8 mt-24 mb-24 xl:mb-0"
      />
    </div>
  )
}

const LockedLessonModalContent = ({
  handleUnlockLesson,
  handleClose,
  modalType,
}: {
  handleUnlockLesson: () => void
  handleClose: () => void
  modalType: 'lesson' | 'topic'
}) => {
  return (
    <div className="flex w-full  max-w-[650px] flex-col p-16 xl:p-0">
      <p className="text-18 font-bold">
        <FormattedMessage
          id={modalType === 'lesson' ? 'unlockThatLesson?' : 'unlockThatTopic?'}
        />
      </p>
      <p className="mt-8 text-14">
        <FormattedMessage
          id={
            modalType === 'lesson'
              ? 'unlockThatLessonDesc'
              : 'unlockThatTopicDesc'
          }
        />
      </p>
      <Button
        size="extralarge"
        bg="blue-gradient"
        rounded="full"
        onClick={handleUnlockLesson}
        className="mt-24"
      >
        <FormattedMessage id="unlock" />
      </Button>
      <Button
        size="extralarge"
        bg="gray-gradient"
        rounded="full"
        onClick={handleClose}
        className="mt-16"
      >
        <FormattedMessage id="cancel" />
      </Button>
    </div>
  )
}

export function LessonSelector({
  lesson,
  index,
}: {
  lesson: ProgramItem
  index: number
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [modalContentType, setModalContentType] = useState<
    'lesson' | 'locked' | null
  >(null)
  const store = useProgramStore()
  const state = useSnapshot(store.state)
  const navigate = useNavigate()
  const isCompleted = lesson.status === 'completed'
  const isMobile = useIsMobile()

  const handleClose = () => {
    setIsOpen(false)
    setModalContentType(null)
  }

  const handleUnlockLesson = () => {
    void store.unlockLesson(lesson.tag)
    handleClose()
  }

  const handleOpen = () => {
    if (
      (lesson.status === 'new' || lesson.tag.includes('Onboarding')) &&
      !lesson.locked
    ) {
      navigate(urls.lesson(lesson.tag))
      store.setSelectedLesson(lesson.id)
    } else if (lesson.locked) {
      setModalContentType('locked')
      setIsOpen(true)
    } else {
      setModalContentType('lesson')
      setIsOpen(true)
    }
  }

  const renderTrigger = (
    <Lesson
      id={lesson.id}
      tag={lesson.tag}
      isSelected={lesson.id === state.selectedLessonId}
      isCompleted={isCompleted}
      inProgress={lesson.status === 'active'}
      index={index + 1}
      title={lesson.lesson_subject}
      type_name={lesson.lesson_name}
      type={lesson.lesson_type}
      isFirstLesson={state.isFirstLesson}
      order={lesson.order}
      rate={lesson.ai_rate?.rate}
      locked={lesson.locked}
    />
  )

  const renderContent =
    modalContentType === 'lesson' ? (
      <LessonSelectorElements
        lessonTag={lesson.tag}
        isCompleted={isCompleted}
      />
    ) : (
      <LockedLessonModalContent
        handleUnlockLesson={handleUnlockLesson}
        handleClose={handleClose}
        modalType="lesson"
      />
    )

  return isMobile ? (
    <Drawer open={isOpen} onClose={handleClose} dismissible={false}>
      <DrawerTrigger
        onClick={() => {
          handleOpen()
        }}
        className="w-full sm:max-w-400"
        id={`lesson-joyride-${index}`}
      >
        {renderTrigger}
      </DrawerTrigger>
      <DrawerContent
        direction="bottom"
        className="mt-24 flex h-auto flex-col rounded-t bg-white"
        onOverlayClick={handleClose}
      >
        <DrawerBar />
        <div className="mt-24 flex h-auto flex-col">
          <DrawerClose
            asChild
            onClick={handleClose}
            className="absolute right-28"
          >
            <div className="flex size-32 shrink-0 items-center justify-center rounded-full bg-gray">
              <Icon iconName="close" className="text-black" />
            </div>
          </DrawerClose>
          {renderContent}
        </div>
      </DrawerContent>
    </Drawer>
  ) : (
    <Dialog.Root
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open)
      }}
    >
      <Dialog.Trigger
        onClick={() => {
          handleOpen()
        }}
        className="w-full"
        id={`lesson-joyride-${index}`}
      >
        {renderTrigger}
      </Dialog.Trigger>

      <Dialog.Content>{renderContent}</Dialog.Content>
    </Dialog.Root>
  )
}

const LangLevelDialogContent = ({
  setIsOpen,
}: {
  setIsOpen: (isOpen: boolean) => void
}) => {
  const store = useProgramStore()
  const state = useSnapshot(store.state)
  const isMobile = useIsMobile()
  const level = state.selectedLevel as keyof typeof LEVELS_MAP
  const isClevel = level === 'C1' || level === 'C2'
  return (
    <>
      <div className="flex justify-between gap-28 px-28">
        <div className="ml-12 text-22 font-bold">
          <FormattedMessage id="chooseLevel" />
        </div>
        {isMobile && (
          <DrawerClose
            asChild
            onClick={() => {
              setIsOpen(false)
            }}
          >
            <div className="flex size-32 shrink-0 items-center justify-center rounded-full bg-gray">
              <Icon iconName="close" className="text-black" />
            </div>
          </DrawerClose>
        )}
      </div>
      <div className="flex-1 overflow-y-auto [scrollbar-width:thin]">
        <LevelsList
          onChange={() => {
            setIsOpen(false)
          }}
        />
        {isClevel && (
          <div className="px-32 pt-24 text-14 text-gray4">
            <FormattedMessage id="ClevelInfo" />
          </div>
        )}
      </div>
    </>
  )
}

export function LevelBottomSheet() {
  const store = useProgramStore()
  const state = useSnapshot(store.state)

  const [isOpen, setIsOpen] = React.useState(false)
  const level = state.selectedLevel as keyof typeof LEVELS_MAP

  const trigger = (
    <button className="flex cursor-pointer items-center gap-8 p-0 text-18 font-semibold text-black outline-0">
      {state.selectedLevel && (
        <FormattedMessage
          id="personalCourse"
          values={{
            level: (
              <FormattedMessage
                id={LEVELS_MAP[level].title as AppMessageKeys}
              />
            ),
          }}
        />
      )}
      <svg xmlns="http://www.w3.org/2000/svg" width={10} height={5} fill="none">
        <path
          fill="#000"
          d="M3.937 4.732.174.969a.61.61 0 0 1-.13-.186.573.573 0 0 1 .115-.61.533.533 0 0 1 .413-.173h7.923c.17 0 .307.058.413.174a.573.573 0 0 1 .159.398c0 .041-.059.174-.175.398L5.13 4.732a.821.821 0 0 1-.283.193.867.867 0 0 1-.314.056.867.867 0 0 1-.314-.056.821.821 0 0 1-.283-.193Z"
        />
      </svg>
    </button>
  )

  return (
    <Drawer
      open={isOpen}
      onClose={() => {
        setIsOpen(false)
      }}
    >
      <DrawerTrigger
        asChild
        onClick={() => {
          setIsOpen((prev) => !prev)
        }}
      >
        {trigger}
      </DrawerTrigger>
      <DrawerContent
        direction="bottom"
        className="mt-24 flex h-auto flex-col rounded-t bg-white"
        overlayClassName="bg-black"
      >
        <DrawerBar />
        <div className="mt-24 flex h-[90dvh] flex-col">
          <LangLevelDialogContent setIsOpen={setIsOpen} />
        </div>
      </DrawerContent>
    </Drawer>
  )
}

interface LevelsListProps {
  onChange: () => void
}

export function LevelsList({ onChange }: LevelsListProps) {
  const store = useProgramStore()
  const state = useSnapshot(store.state)

  const levels = state.programTree.map(
    (x) => x.level,
  ) as (keyof typeof LEVELS_MAP)[]

  return (
    <div className="mt-28 flex flex-col gap-8 px-16">
      {levels.map((level) => {
        const title = LEVELS_MAP[level].title
        const subtitle = LEVELS_MAP[level].subtitle
        return (
          <div
            key={title}
            className="flex cursor-pointer items-center gap-8 rounded-12 bg-gray-light px-12 py-16"
            onClick={() => {
              store.setSelectedLevel(level)
              onChange()
            }}
          >
            <div className="flex size-20 items-center justify-center rounded-full border-2 border-main bg-white">
              {state.selectedLevel === level && (
                <div className="size-12 rounded-full bg-main"></div>
              )}
            </div>

            <div className="flex flex-col items-start justify-between text-16 font-bold">
              <h3>
                <FormattedMessage id={title as AppMessageKeys} />
              </h3>
              <p className="text-14 text-gray5">
                <FormattedMessage
                  id="Approximately"
                  values={{ level: subtitle }}
                />
              </p>
            </div>
          </div>
        )
      })}
    </div>
  )
}

interface TopicCard {
  title: string
  index: number
  lessonsAmount: number
  isActive?: boolean
  isLocked?: boolean
  onClick: () => void
}

function TopicCard({
  title,
  index,
  lessonsAmount,
  isActive,
  isLocked,
  onClick,
  onUnlock,
}: TopicCard & { onUnlock?: () => void }) {
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = () => {
    if (isLocked) {
      setIsOpen(true)
    } else {
      onClick()
    }
  }

  const handleUnlock = () => {
    onUnlock?.()
    setIsOpen(false)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      <div
        className={cn(
          'relative flex h-auto min-w-[130px] max-w-[130px] cursor-pointer flex-col overflow-hidden rounded-18 bg-gray-light p-12',
          isActive && 'bg-active-topic-card shadow-active-topic-card',
        )}
        onClick={handleClick}
      >
        <img
          src={`/images/program/topics/topic-${index}.png`}
          className="size-48"
        />

        <div
          className={cn(
            'mt-8 font-nunito-10-semicondensed text-10 font-extrabold uppercase',
            isActive
              ? 'text-topic-card-header-active'
              : 'text-topic-card-header',
          )}
        >
          <FormattedMessage id="Topic" />
        </div>

        <div
          className={cn(
            'mt-4 line-clamp-2 overflow-hidden text-ellipsis break-words text-18 font-extrabold leading-6',
            isActive ? 'text-white' : 'text-black',
          )}
        >
          {title}
        </div>

        <div
          className={cn(
            'mt-auto font-nunito-10-semicondensed text-12 font-medium opacity-50',
            isActive ? 'text-white' : 'text-black',
          )}
        >
          {lessonsAmount}{' '}
          <FormattedMessage
            id="lessonsPlural"
            values={{ count: lessonsAmount }}
          />
        </div>
        <AnimatePresence mode="wait">
          {isLocked && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="absolute bottom-12 right-12"
            >
              <Icon
                iconName="lockClosed"
                className="text-black opacity-30 "
                size="sm"
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
        <Dialog.Content>
          <LockedLessonModalContent
            handleUnlockLesson={handleUnlock}
            handleClose={handleClose}
            modalType="topic"
          />
        </Dialog.Content>
      </Dialog.Root>
    </>
  )
}

function TopicsList() {
  const store = useProgramStore()
  const state = useSnapshot(store.state)

  const topics =
    state.programTree.find((x) => x.level === state.selectedLevel)?.items ?? []

  const isTopicLocked = (topic: TopicItem) => {
    return topic.items.every((unit) =>
      unit.items.every((lesson) => lesson.locked === true),
    )
  }

  const handleUnlockTopic = async (topic: TopicItem) => {
    const firstLesson = topic.items
      .flatMap((unit) => unit.items)
      .reduce((min, current) => (current.order < min.order ? current : min))

    await store.unlockLesson(firstLesson.tag)
  }

  return (
    <div className="flex flex-col gap-12 bg-white px-16 pb-16 xl:w-[calc(100%-300px)] xl:gap-0  xl:rounded-24 xl:p-0 xl:shadow-drop">
      <div className="hidden text-16 font-extrabold text-black  xl:flex xl:items-center xl:justify-between xl:p-16 xl:pb-0">
        <FormattedMessage id="chooseTopic" />
        <Icon iconName="arrowRight" className="text-light" size="sm" />
      </div>
      <div
        className={cn(
          'no-scrollbar xl:yes-scrollbar flex gap-16 overflow-auto xl:gap-12 xl:p-16 xl:pt-12  xl:[scrollbar-width:thin]',
        )}
        id="lessons-units"
      >
        {topics.map((x, i) => {
          const totalLessons = x.items
            .map((unit) => unit.items.length)
            .reduce((acc, amount) => acc + amount, 0)

          const isLocked = isTopicLocked(x)

          return (
            <TopicCard
              key={x.program}
              index={i + 1}
              lessonsAmount={totalLessons}
              isActive={state.selectedProgram === x.program}
              isLocked={isLocked}
              title={x.program}
              onClick={() => {
                store.setSelectedProgram(x.program)
              }}
              onUnlock={() => void handleUnlockTopic(x)}
            />
          )
        })}
      </div>
    </div>
  )
}

interface UnitHeaderProps {
  title: string
  id: string
}

function UnitHeader({ title, id }: UnitHeaderProps) {
  const isMobile = useIsMobile()
  return (
    <div className="px-16 xl:px-0" id={`${id}-header`}>
      <div className="text-24 font-extrabold text-black xl:text-18 xl:leading-none ">
        {isMobile ? (
          <FormattedMessage id="unit" />
        ) : (
          <FormattedMessage id="unitDesktop" values={{ title }} />
        )}
      </div>

      <div className="overflow-hidden text-ellipsis text-nowrap text-18 font-extrabold leading-none text-black xl:hidden">
        {title}
      </div>
    </div>
  )
}

function Units() {
  const store = useProgramStore()
  const selectedCycles = useSnapshot(store.state).selectedCycles

  return (
    <div className="mx-auto my-0 mb-100 flex w-full flex-col gap-16 bg-white p-16 xl:m-0  xl:mt-28 xl:w-[calc(100%-300px)] xl:rounded-24 xl:p-24 xl:shadow-drop   ">
      {selectedCycles.map((cycle, i) => {
        return (
          <Unit
            key={cycle.index}
            title={cycle.title}
            lessons={cycle.items}
            id={`units-${i}`}
            isFirstUnit={i === 0}
          />
        )
      })}
    </div>
  )
}

const UnitDashedLine = ({ id }: { id: string }) => {
  return (
    <div className="relative ml-6 mt-18 hidden h-[calc(100%-130px)] w-[4px] xl:block">
      <svg width="4" height="100%" className="absolute left-0 top-0">
        <defs>
          <pattern
            id={`dotted-pattern-${id}`}
            width="4"
            height="25"
            patternUnits="userSpaceOnUse"
          >
            <rect width="4" height="16" rx="2" fill="#E4E7F2" />
          </pattern>
        </defs>
        <rect width="4" height="100%" fill={`url(#dotted-pattern-${id})`} />
      </svg>
    </div>
  )
}

interface UnitProps {
  title: string
  lessons: readonly ProgramItem[]
  id: string
  isFirstUnit: boolean
}

function Unit({ title, lessons, id, isFirstUnit }: UnitProps) {
  const store = useProgramStore()

  const { selectedLessonId, isFirstLesson, hints } = useSnapshot(store.state)

  useEffect(() => {
    if (selectedLessonId && !isFirstLesson) {
      const targetElement = document.getElementById(String(selectedLessonId))
      if (targetElement) {
        const elementPosition =
          targetElement.getBoundingClientRect().top + window.scrollY
        setTimeout(() => {
          window.scrollTo({ top: elementPosition - 100, behavior: 'smooth' })
        }, 200)
      }
    }
  }, [selectedLessonId, isFirstLesson])

  return (
    <div>
      <UnitHeader title={title} id={id} />

      <div className="flex h-full">
        <UnitDashedLine id={id} />
        <div className="relative mt-12 flex w-full flex-wrap gap-16 msm:flex-col xl:ml-20 xl:mt-18 xl:flex-col xl:flex-nowrap">
          {sortBy(lessons, (x) => x.order).map((lesson, i) => {
            return (
              <div key={lesson.id}>
                <LessonSelector lesson={lesson} index={i} />
                <GoToVocabHint
                  shouldViewHint={
                    i === 0 && isFirstUnit && !!hints?.marketingPracticeHint
                  }
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const GoToVocabHint = ({ shouldViewHint }: { shouldViewHint: boolean }) => {
  const store = useProgramStore()
  const navigate = useNavigate()
  if (!shouldViewHint) return null
  const handleOpenHint = () => {
    void store.deleteMarketingPracticeHint()
    navigate(urls.practice)
  }

  return (
    <div className="relative mt-16 hidden h-[120px] w-full select-none overflow-hidden rounded-18 bg-user-message p-18 xl:flex">
      <div className="z-10 flex flex-col gap-12">
        <p className="text-20 font-semibold text-black">
          <FormattedMessage
            id="trainWords"
            values={{
              section: (
                <span className="font-extrabold">
                  <FormattedMessage id="vocabPlus" />
                </span>
              ),
            }}
          />
        </p>
        <Button
          size="custom"
          bg="blue-gradient"
          rounded="full"
          className="h-[42px] w-fit px-[22px] text-16 font-bold"
          onClick={handleOpenHint}
        >
          <FormattedMessage id="goToVocabulary" />
        </Button>
      </div>
      <Rive
        src="/animations/banner_vocab.riv"
        className="absolute right-0 top-0 z-20 h-[120px] w-[300px]"
      />
      <video
        src="/videos/edman_banner.mp4"
        className="absolute right-0 top-0 z-10 h-[120px] w-[300px]"
        autoPlay
        muted
        loop
      />
    </div>
  )
}

interface LessonProps {
  title: string
  type_name: string
  tag: string
  id: number
  index: number
  type: string
  isSelected?: boolean
  isCompleted?: boolean
  inProgress?: boolean
  isFirstLesson: boolean
  order: number
  rate: number | undefined
  locked?: boolean | null
}

const pseudoNumber =
  'relative xl:before:content-[attr(data-index)] xl:before:absolute xl:before:-left-[36px] xl:before:top-0 xl:before:flex xl:before:items-center xl:before:justify-center xl:before:size-[28px] xl:before:rounded-full xl:before:bg-gray-light xl:before:text-14 xl:before:font-extrabold xl:before:text-purple3'

function Lesson(props: LessonProps) {
  const store = useProgramStore()
  const state = useSnapshot(store.state)
  const currentCycleFirstItemId = state.currentCycle?.items[0].id
  const isMobile = useIsMobile()

  if (props.isFirstLesson && props.id === currentCycleFirstItemId) {
    return (
      <div
        className={cn(
          'relative h-[240px] w-full flex-1 shrink-0 rounded-18 bg-gray-light p-18 msm:max-w-full xl:h-fit xl:bg-[#CEEBF8]',
          pseudoNumber,
        )}
        onClick={(e) => {
          e.stopPropagation()
        }}
        data-index={props.index}
      >
        <div className="absolute right-10 top-10 hidden flex-col  text-12  font-semibold xl:flex ">
          <div className="flex gap-10 opacity-40">
            {TYPES_WITH_SOUND.includes(props.type) && (
              <Icon iconName="headphone" className="size-14" />
            )}
            {
              <FormattedMessage
                id="lessonType"
                values={{
                  type: props.type_name,
                  count:
                    TYPES_TIME_MAP[props.type as keyof typeof TYPES_TIME_MAP],
                  min: <FormattedMessage id="min" />,
                }}
              />
            }
          </div>
        </div>
        <img
          className="absolute bottom-8 right-14 h-[176px] w-[179px] xl:hidden"
          src="/images/program/start-lesson.png"
          alt=""
        />
        <div className="hidden w-full items-center gap-12 xl:flex">
          <LessonProgressIcon
            isCompleted={props.isCompleted}
            inProgress={props.inProgress}
            type={props.type}
          />
          <div className="text-start">
            <div className="text-14 font-extrabold text-black opacity-30">
              <FormattedMessage id="Lesson" /> {props.index}
            </div>

            <div className="mt-2 line-clamp-2 overflow-hidden text-ellipsis break-words text-18 font-bold leading-5 text-black">
              {props.title}
            </div>
          </div>
        </div>
        <div className="relative flex h-full flex-col text-start ">
          <div className="text-14 font-extrabold text-black opacity-30 xl:hidden">
            <FormattedMessage id="Lesson" /> {props.index}
          </div>

          <div className="mt-2 line-clamp-2 overflow-hidden text-ellipsis break-words text-18 font-bold leading-5 text-black xl:hidden">
            {props.title}
          </div>

          <LinkButton
            to={urls.lesson(props.tag)}
            onClick={() => {
              void store.deleteLessonsHint()
            }}
            size="custom"
            bg={isMobile ? 'black-gradient' : 'blue-gradient'}
            rounded={isMobile ? 'default' : 'full'}
            className="mt-auto h-44 max-w-[140px] text-16 font-bold xl:mt-18 xl:w-full xl:max-w-full xl:shadow-none"
          >
            <FormattedMessage id="Start lesson" />
          </LinkButton>
        </div>
      </div>
    )
  }

  return (
    <div
      className={cn(
        'relative flex w-full cursor-pointer items-baseline rounded-18 bg-gray-light p-16',
        pseudoNumber,
      )}
      id={String(props.id)}
      data-index={props.index}
    >
      <div className="absolute right-10 top-10 flex flex-col  text-12 font-semibold">
        <div className="flex gap-10 opacity-40">
          {TYPES_WITH_SOUND.includes(props.type) && (
            <Icon iconName="headphone" className="size-14" />
          )}
          {
            <FormattedMessage
              id="lessonType"
              values={{
                type: props.type_name,
                count:
                  TYPES_TIME_MAP[props.type as keyof typeof TYPES_TIME_MAP],
                min: <FormattedMessage id="min" />,
              }}
            />
          }
        </div>
        <div className="mt-4 flex justify-end">
          {props.rate &&
            !props.inProgress &&
            [...Array(3).keys()].map((i) => {
              if (!props.rate) return null
              return (
                <img
                  key={`star-${i}`}
                  src={
                    i < props.rate
                      ? '/images/star.png'
                      : '/images/emptyStar.png'
                  }
                  className="size-14"
                />
              )
            })}
          {props.inProgress && (
            <p className="font-extrabold opacity-40">
              <FormattedMessage id="inProgress" />
            </p>
          )}
        </div>
      </div>

      <div className="flex w-full items-center gap-12">
        <LessonProgressIcon
          isCompleted={props.isCompleted}
          inProgress={props.inProgress}
          type={props.type}
          locked={props.locked}
        />
        <div className="text-start">
          <div className="text-14 font-extrabold text-black opacity-30">
            <FormattedMessage id="Lesson" /> {props.index}
          </div>

          <div
            className={cn(
              'mt-2 line-clamp-2 overflow-hidden text-ellipsis break-words text-18 font-bold leading-5 text-black',
              props.locked && 'opacity-30',
            )}
          >
            {props.title}
          </div>
        </div>
      </div>
    </div>
  )
}

const LessonProgressIcon = (props: {
  inProgress?: boolean
  isCompleted?: boolean
  locked?: boolean | null
  type: string
}) => {
  return props.inProgress ? (
    <div className="relative flex size-80 min-h-80 min-w-80 items-center justify-center">
      <img src="/images/progress.svg" className="size-full" />
      <div
        className={cn(
          'absolute flex size-60 items-center justify-center overflow-hidden rounded-full',
          props.isCompleted ? 'bg-green-40' : 'bg-white',
        )}
      >
        <img className="w-2/3" src={LESSON_TYPE_ICONS[props.type]} alt="" />
      </div>
    </div>
  ) : (
    <div className="relative">
      <div
        className={cn(
          'relative flex size-80 min-h-80 min-w-80 shrink-0 items-center justify-center rounded-full border-4 p-6',
          props.isCompleted
            ? 'border-green bg-green-40'
            : 'border-lesson-icon-border',
          props.locked && 'opacity-30',
        )}
      >
        <div
          className={cn(
            'flex size-full items-center justify-center overflow-hidden rounded-full',
            props.isCompleted ? 'bg-green-40' : 'bg-white',
          )}
        >
          <img className="w-2/3" src={LESSON_TYPE_ICONS[props.type]} alt="" />
        </div>
        {props.isCompleted && (
          <div className="absolute -bottom-2 -left-2 flex size-24 items-center justify-center rounded-full border-2 border-white bg-green">
            <svg
              width="10"
              height="8"
              viewBox="0 0 10 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 4.50033L2.48217 6.53037C2.94672 7.16663 3.90024 7.15427 4.34816 6.50616L8.15346 1"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
      </div>
      <AnimatePresence mode="wait">
        {props.locked && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          >
            <Icon
              iconName="lockClosed"
              className="size-32 text-neutralgray600"
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
