import { urls } from '../urls.ts'
import { ProfileSections } from './profile.ts'

export const NAVBAR_EXCEPTIONS = [
  urls.profile(ProfileSections.Logout),
  urls.profile(ProfileSections.ManageAccount),
  urls.profile(ProfileSections.ChangeOnboardingInfo),
  urls.profile(ProfileSections.ManageSubscription),
  urls.profile(ProfileSections.CancelSubscription),
  urls.profile(ProfileSections.PostChangeSubscription),
  urls.profile(ProfileSections.Faq),
  urls.profile(ProfileSections.Support),
  urls.profile(ProfileSections.Notifications),
  urls.profile(ProfileSections.AppLanguage),
  urls.profile(ProfileSections.About),
]
