import { MarkData } from '../api/authApi.ts'
import { AppLanguages } from '../translations/messages.ts'

export interface RouseState {
  openDrawer: boolean
}

export interface HideTooltip {
  keyboard?: boolean
  history?: boolean
  holdToRecord?: boolean
}
export interface StorageData {
  user: {
    user_id: number
    access: string
    refresh: string
    login: string
    account_confirmed: boolean
  }
  lastRoute: {
    url: string
    state?: RouseState
  }
  hideProgramTour: boolean
  markData: MarkData
  language: AppLanguages
  whatIsNewHash: number
  hideTooltips: HideTooltip
}

export type StorageKeys = keyof StorageData

export class AppLocalStorage {
  constructor(private storage: Storage) {}

  getKey(key: StorageKeys) {
    const version = 'v5'
    const commonKey = `${version}-${key}`
    if (key == 'user' || key == 'markData') {
      return commonKey
    } else {
      const user = this.get('user')
      if (user) {
        return commonKey + '-' + user.user_id
      } else {
        return commonKey
      }
    }
  }

  get<T extends StorageKeys>(key: T): StorageData[T] | null {
    const data = this.storage.getItem(this.getKey(key))

    if (data !== null) {
      return JSON.parse(data) as StorageData[T]
    }

    return null
  }

  set<T extends StorageKeys>(key: T, value: StorageData[T] | undefined) {
    if (value == undefined) {
      this.remove(key)
    } else {
      this.storage.setItem(this.getKey(key), JSON.stringify(value))
    }
  }

  remove(key: StorageKeys) {
    this.storage.removeItem(this.getKey(key))
  }
}
