import { motion } from 'framer-motion'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { cn } from '../shared/lib/utils.ts'
import { Button } from '../shared/ui/button/button.tsx'
import { Icon } from '../shared/ui/icon/icon.tsx'
import { urls } from '../shared/urls.ts'
import { useAppModule } from '../features/appContext.ts'
import {
  AppLanguages,
  AppMessageKeys,
  languagesMap,
} from '../shared/translations/messages.ts'
import { useSnapshot } from 'valtio'
import {
  RuImage,
  GbImage,
  ViImage,
  TrImage,
} from '../shared/ui/images/images.tsx'

export function NativeLangPage() {
  const appStore = useAppModule().appStore
  const state = useSnapshot(appStore.state)

  const navigate = useNavigate()

  const localeImgs = {
    ru: RuImage,
    en: GbImage,
    tr: TrImage,
    vi: ViImage,
  }

  const handleLanguageSelect = (lang: AppLanguages) => {
    appStore.setLanguage(lang)
  }

  const handleNextStep = () => {
    navigate(urls.onboarding())
  }

  return (
    <div className="relative mx-auto flex min-h-dvh w-full max-w-[500px] flex-col items-center bg-light text-black transition-colors">
      <div className="flex min-h-0 w-full flex-1 flex-col  overflow-y-auto overflow-x-hidden px-12 pb-32 sm:pb-0">
        <motion.div
          className="my-auto flex w-full max-w-[860px] flex-col"
          transition={{ duration: 0.3, ease: 'easeOut' }}
        >
          <div className="flex flex-col ">
            <div className="text-center text-[30px] font-extrabold">
              <FormattedMessage id="nativeLang.selectYourNativeLanguage" />
            </div>

            <div className="mt-24 text-center text-16 text-gray4">
              <FormattedMessage
                id="nativeLang.selectDescription"
                values={{
                  boldText: (
                    <span className="font-bold">
                      <FormattedMessage id="nativeLang.selectDescription2" />
                    </span>
                  ),
                }}
              />
            </div>
            <div className="mt-24 w-fit rounded-24 rounded-tl-[0px] bg-white px-18 py-12 text-18 font-semibold">
              <FormattedMessage id="nativeLang.helloThere" />
            </div>

            <div className="mt-32 flex w-full flex-col gap-4 sm:gap-8">
              {languagesMap.map((option, index) => {
                const Img = localeImgs[option]
                return (
                  <button
                    key={index}
                    className={cn(
                      'flex h-56 items-center justify-start gap-12 rounded-24 px-14 py-10 text-18 font-extrabold transition-all hover:opacity-80',
                      option === state.language
                        ? 'bg-user-message text-black'
                        : 'bg-white text-default',
                    )}
                    onClick={() => {
                      handleLanguageSelect(option as AppLanguages)
                    }}
                  >
                    <Img className="rounded-4" width={32} height={32} />
                    <FormattedMessage id={option as AppMessageKeys} />
                  </button>
                )
              })}
            </div>

            <div className="mt-48 flex gap-24">
              <Button
                bg="blue-gradient"
                size="extralarge"
                rounded="full"
                className="w-full justify-center"
                disabled={!state.language}
                onClick={handleNextStep}
              >
                <span className="mr-8">
                  <FormattedMessage id="Continue" />
                </span>
                <Icon iconName="arrowRight" />
              </Button>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  )
}
