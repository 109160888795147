import { AppMessageKeys } from '../translations/messages.ts'

export const onboardingSteps = [
  {
    title: 'onboarding.hello.shortTitle',
    question: 'onboarding.hello',
    step: 'hello',
  },
  {
    title: 'onboarding.sex.shortTitle',
    question: 'onboarding.sex',
    step: 'sex',
    video: true,
  },
  {
    title: 'onboarding.age.shortTitle',
    question: 'onboarding.age',
    step: 'age',
  },
  {
    title: 'onboarding.profession.shortTitle',
    question: 'onboarding.profession',
    step: 'profession',
  },
  {
    title: 'onboarding.interests.shortTitle',
    question: `onboarding.interests`,
    step: 'interests',
  },
  {
    title: 'onboarding.level.shortTitle',
    question: 'onboarding.level',
    step: 'level',
  },
  {
    title: 'onboarding.goal.shortTitle',
    question: 'onboarding.goal',
    step: 'goal',
  },
  {
    title: 'onboarding.goal_answer',
    question: 'onboarding.goal_answer',
    step: 'goal_answer',
  },
  {
    title: 'onboarding.improve.shortTitle',
    question: 'onboarding.improve',
    step: 'improves',
  },
  {
    title: 'onboarding.duration.shortTitle',
    question: 'onboarding.duration',
    step: 'duration',
  },
  {
    title: 'onboarding.name.shortTitle',
    // question: isNoDemoDomain ? 'onboarding.name' : 'onboarding.nameWithDemo',
    question: 'onboarding.name',

    step: 'name',
  },
  //   ...(isNoDemoDomain
  //     ? []
  //     : [
  //         {
  //           title: 'Давай потренируемся!',
  //           question: 'onboarding.askDemo',
  //           step: 'askdemo',
  //         } as const,
  //       ]),
  {
    title: '',
    question: 'onboarding.program',
    step: 'generation' as const,
  },
  {
    title: '',
    question: 'onboarding.program',
    step: 'program',
  },
] as const
export type OnboardingStep = (typeof onboardingSteps)[number]['step']

export type LangLevels = 'A1' | 'A2' | 'B1' | 'B2' | 'C1' | 'C2'

export enum SexKeys {
  Male = 'male',
  Female = 'female',
  NoAnswer = 'noanswer',
}

export enum DurationKeys {
  Five = '5',
  Ten = '10',
  Fifteen = '15',
  Thirty = '30',
  Forty = '40',
  Sixty = '60',
}
export enum InterestKeys {
  Food = 'food',
  FineArts = 'fineArts',
  Science = 'science',
  Sports = 'sports',
  Nature = 'nature',
  Travel = 'travel',
  HomeAndDecor = 'homeAndDecor',
  Music = 'music',
  Health = 'health',
  Shopping = 'shopping',
  Psychology = 'psychology',
  Cinema = 'cinema',
  Pets = 'pets',
  Fashion = 'fashion',
  Technologies = 'technologies',
  Games = 'games',
  Education = 'education',
  Theater = 'theater',
  CulinaryArts = 'culinaryArts',
  Finance = 'finance',
  History = 'history',
  Photography = 'photography',
}

export enum ProfessionKeys {
  Marketer = 'marketingSpecialist',
  Researcher = 'researchSpecialist',
  Teacher = 'teacher',
  SalesManager = 'salesManager',
  HRSpecialist = 'humanResourcesSpecialist',
  ITSpecialist = 'itSpecialist',
  PublicRelationsSpecialist = 'publicRelationsSpecialist',
  ContentWriterEditor = 'contentWriterEditor',
  Lawyer = 'lawyer',
  Accountant = 'accountant',
  Entrepreneur = 'entrepreneur',
  SoftwareDeveloper = 'softwareDeveloper',
  Psychologist = 'psychologist',
  Nurse = 'nurse',
  RealEstateAgent = 'realEstateAgent',
  Architect = 'architect',
  Other = 'other',
}

export enum AgeKeys {
  Under18 = '18-',
  Teenager = '18-25',
  Adult = '26-34',
  MiddleAged = '35-44',
  Senior = '45-54',
  Elderly = '55+',
  NoAnswer = 'noanswer',
}

export enum GoalKeys {
  DreamJob = 'dreamJob',
  ForeignCountry = 'foreignCountries',
  MoviesAndBooks = 'moviesAndBooks',
  Travel = 'travel',
  Speaking = 'speak',
}

export enum ImproveKeys {
  Pronunciation = 'pronunciation',
  Vocabulary = 'vocabulary',
  Grammar = 'grammar',
  Reading = 'reading',
  Writing = 'writing',
  Speaking = 'speaking',
  Listening = 'listening',
}

export enum LanguageLevelKeys {
  A1_1 = 'A1-1',
  A1_2 = 'A1-2',
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2',
  C1 = 'C1',
  C2 = 'C2',
}

export interface LanguageLevelData {
  key: LanguageLevelKeys
  langLevel: LangLevels
  displayKey: AppMessageKeys
  icon: string
}

export const languageLevelData: LanguageLevelData[] = [
  {
    key: LanguageLevelKeys.A1_1,
    langLevel: 'A1',
    displayKey: 'onboarding.a1-1',
    icon: '❌',
  },
  {
    key: LanguageLevelKeys.A1_2,
    langLevel: 'A1',
    displayKey: 'onboarding.a1-2',
    icon: '👋',
  },
  {
    key: LanguageLevelKeys.A2,
    langLevel: 'A2',
    displayKey: 'onboarding.a2',
    icon: '🗣️',
  },
  {
    key: LanguageLevelKeys.B1,
    langLevel: 'B1',
    displayKey: 'onboarding.b1',
    icon: '😊',
  },
  {
    key: LanguageLevelKeys.B2,
    langLevel: 'B2',
    displayKey: 'onboarding.b2',
    icon: '📚',
  },
  {
    key: LanguageLevelKeys.C1,
    langLevel: 'C1',
    displayKey: 'onboarding.с1',
    icon: '🕵️',
  },
  {
    key: LanguageLevelKeys.C2,
    langLevel: 'C2',
    displayKey: 'onboarding.с2',
    icon: '🎓',
  },
]
