import Joyride, { EVENTS } from 'react-joyride'
import { useStores } from '../chats/chatView.tsx'
import { useSnapshot } from 'valtio'
import { useIsMobile } from '../../shared/lib/hooks.ts'
import { joyrideProps } from '../../shared/data/joyride.tsx'
import { createPortal } from 'react-dom'

const getSnowflakeSteps = (hintButtonId: number) => {
  return [
    {
      target: `#ai-comment-button-${hintButtonId}`,
      content: (
        <span>
          Превратите ошибки в прогресс!{' '}
          <span className="font-extrabold">Нажмите </span>на снежинку, чтобы
          увидеть исправленное предложение.
        </span>
      ),
      disableBeacon: true,
      placement: 'top' as const,
      styles: {
        tooltipContainer: {
          padding: '24px',
        },
        spotlight: {
          borderRadius: '50%',
        },
        tooltip: {
          width: '300px',
          paddingBottom: '16px',
        },
      },
      offset: 40,
      spotlightPadding: 40,
      imagePlace: 'right',
      disableButton: true,
      spotlightClicks: true,
    },
  ]
}

const getGreenCheckSteps = (hintButtonId: number) => {
  return [
    {
      target: `#no-ai-comment-button-${hintButtonId}`,
      content: 'Зеленая галочка – вы написали идеально и без ошибок!',
      disableBeacon: true,
      placement: 'top' as const,
      styles: {
        tooltipContainer: {
          padding: '24px',
        },
        spotlight: {
          borderRadius: '50%',
        },
        tooltip: {
          width: '300px',
          transform: 'translate(5px, 0px)',
        },
      },
      offset: 40,
      spotlightPadding: 40,
      imagePlace: 'top-left',
      disableButton: false,
    },
  ]
}

const getVocabularySteps = (word: string, lastAddVocabWord: string | null) => {
  return [
    {
      target: '#vocabulary-words-wrapper',
      content: (
        <>
          <span className="font-extrabold">Нажмите </span>
          <span>
            на одно из трех слов и добавьте его в словарь, чтобы узнать его
            перевод и произношение.
          </span>
        </>
      ),
      disableBeacon: true,
      placement: 'top' as const,
      spotlightClicks: true,
      styles: {
        tooltipContainer: {
          padding: '24px',
        },
        spotlight: {
          borderRadius: '22px',
        },
        tooltip: {
          width: '300px',
          paddingBottom: '16px',
        },
      },
      spotlightPadding: 8,
      imagePlace: 'top-right',
      disableButton: true,
    },
    {
      target: '#add-word-drawer-button',
      content: (
        <span>
          Подтвердите, что нужно добавить{' '}
          <span className="font-extrabold">‘{word}’</span> в словарик.
        </span>
      ),
      disableBeacon: true,
      disableButton: true,
      placement: 'top' as const,
      styles: {
        tooltipContainer: {
          padding: '24px',
          transform: 'translate(5px, 10px)',
        },
        spotlight: {
          borderRadius: '28px',
        },
        tooltip: {
          width: '300px',
          paddingBottom: '16px',
        },
      },
      spotlightPadding: 5,
      spotlightClicks: true,
      imagePlace: 'top-right',
    },
    {
      target: `#word-block-${lastAddVocabWord}`,
      content: (
        <div className="flex flex-col gap-16">
          <p>
            Я сохранил{' '}
            <span className="font-extrabold">‘{lastAddVocabWord}’</span> здесь.
            Добавляйте и другие незнакомые слова.
          </p>
        </div>
      ),
      disableBeacon: true,
      placement: 'left' as const,
      imagePlace: 'left',
      spotlightPadding: 8,
      spotlightClicks: false,
      disableButton: false,
      disableOverlayClose: true,
      styles: {
        tooltipContainer: {
          padding: '24px',
        },
        spotlight: {
          borderRadius: '22px',
        },
        tooltip: {
          width: '300px',
          paddingLeft: '24px',
          paddingBottom: '16px',
        },
      },
    },
  ]
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const ChatJoyride = () => {
  const store = useStores().chatStore
  const isMobile = useIsMobile()
  const state = useSnapshot(store.state)
  const isVocabHint = state.currentHint === 'start_vocabulary_hint'
  const isGreenCheckHint = state.currentHint === 'show_positive_ortho'
  const isSnowflakeHint = state.currentHint === 'chat_onboarding_snowflake_hint'

  const {
    shouldViewHint,
    hints,
    addWordDrawer: { word: selectedDrawerWord },
    lastAddVocabWord,
    joyrideTours: { stepIndex },
  } = state
  const { hintButtonId, contexts: hintContexts } = hints ?? {}
  const isSecondIndex = stepIndex === 2
  const isVocabEndStep = isSecondIndex && isVocabHint
  const shouldEndTour = isGreenCheckHint || isVocabEndStep || isSnowflakeHint
  //   const handleSpotlightClick = () => {
  //     void store.handleWord()
  //     store.handleCloseAddDrawer()
  //   }

  //   const handleOpenDrawer = () => {
  //     store.openVocabularyDrawer()
  //   }

  //   useLastStepSpotlightClick({
  //     isLastStep: isFirstIndex && isVocabHint,
  //     onSpotlightClick: handleSpotlightClick,
  //   })

  //   useLastStepSpotlightClick({
  //     isLastStep: isSecondIndex && isVocabHint,
  //     onSpotlightClick: handleOpenDrawer,
  //   })

  const handleJoyrideCallback = ({ type }: { type: string }) => {
    if (shouldEndTour && type === EVENTS.STEP_AFTER) {
      store.endTour(isVocabEndStep)
    }
  }

  const getSteps = () => {
    if (isGreenCheckHint && hintButtonId) {
      return getGreenCheckSteps(hintButtonId)
      // eslint-disable-next-line valtio/state-snapshot-rule
    } else if (isVocabHint && hintContexts) {
      // eslint-disable-next-line valtio/state-snapshot-rule
      return getVocabularySteps(selectedDrawerWord, lastAddVocabWord)
    } else if (isSnowflakeHint && hintButtonId) {
      return getSnowflakeSteps(hintButtonId)
    }
  }

  if (!shouldViewHint || isMobile) return null

  return createPortal(
    <Joyride
      steps={getSteps()}
      run={state.shouldViewHint && !isMobile}
      callback={handleJoyrideCallback}
      {...joyrideProps}
      stepIndex={state.joyrideTours.stepIndex}
    />,
    document.body,
  )
}
