import messages_ru from './ru.json'
import messages_en from './en.json'
import messages_tr from './tr.json'
import messages_vi from './vi.json'

export type AppLanguages = 'en' | 'ru' | 'tr' | 'vi'
export type AppMessages = typeof messages_ru

export type AppMessageKeys = keyof AppMessages
export const messages: Record<AppLanguages, AppMessages> = {
  en: messages_en,
  ru: messages_ru,
  tr: messages_tr,
  vi: messages_vi,
}

export const languages = new Set(Object.keys(messages))
export const languagesMap: AppLanguages[] = ['en', 'ru', 'tr', 'vi']
