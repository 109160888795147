import { FormattedMessage } from 'react-intl'
import { Button } from '../shared/ui/button/button.tsx'
import { Icon } from '../shared/ui/icon/icon.tsx'
import { useNavigate } from 'react-router-dom'
import { TextInput } from '../shared/ui/textInput/textInput.tsx'
import {
  Drawer,
  DrawerBar,
  DrawerClose,
  DrawerContent,
} from '../shared/ui/drawer/drawer.tsx'
import { useState } from 'react'
import { useAppModule } from '../features/appContext.ts'
import { validateEmail } from '../shared/lib/utils.ts'
import { Spinner } from '../shared/ui/spinner.tsx'
import { urls } from '../shared/urls.ts'
import { Toasts } from '../features/toasts/toasts.tsx'
import { useIsMobile } from '../shared/lib/hooks.ts'
import { Dialog } from '../shared/ui/dialog/dialog.tsx'

export function PasswordRecovery() {
  const store = useAppModule().authStore
  const navigate = useNavigate()

  const [state, setState] = useState({
    email: '',
    isEmailValid: false,
    status: null as 'success' | 'error' | null,
    loading: false,
  })

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value
    setState((prevState) => ({
      ...prevState,
      email,
      isEmailValid: validateEmail(email),
    }))
  }

  const handleButtonClick = async () => {
    setState((prevState) => ({ ...prevState, loading: true }))
    const status = await store.recoveryPassword(state.email)
    setState((prevState) => ({ ...prevState, status, loading: false }))
  }

  return (
    <div className="mx-auto flex max-w-800 flex-col p-16">
      <div>
        <Button
          bg="transparent"
          size="custom"
          className="flex items-center gap-16 px-0"
          onClick={() => {
            navigate(-1)
          }}
        >
          <Icon iconName="arrowLeft" size="large" />
          <p className="text-18 font-bold">
            <FormattedMessage id="resetPassword" />
          </p>
        </Button>
      </div>
      <TextInput
        className="mt-32 rounded-full"
        placeholder="E-mail"
        value={state.email}
        onChange={handleEmailChange}
      />
      <Button
        type="submit"
        rounded="full"
        bg="blue-gradient-shadow-inset"
        size="extralarge"
        className="mt-24 w-full"
        disabled={!state.isEmailValid || state.loading}
        onClick={() => void handleButtonClick()}
      >
        {state.loading ? <Spinner /> : <FormattedMessage id="sendEmail" />}
      </Button>
      {state.status === 'success' && <SendEmailDrawer email={state.email} />}
      <Toasts />
    </div>
  )
}

const SendEmailDrawer = ({ email }: { email: string }) => {
  const [open, setOpen] = useState<boolean>(true)
  const navigate = useNavigate()
  const isMobile = useIsMobile()

  const handleClose = () => {
    setOpen(false)
    setTimeout(() => {
      navigate(urls.login)
    }, 300)
  }
  return isMobile ? (
    <Drawer
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          setOpen(false)
        }
      }}
    >
      <DrawerContent
        direction="bottom"
        className="mx-auto mt-24 flex h-auto max-w-800 flex-col rounded-t bg-white"
      >
        <DrawerBar />
        <PasswordRecoveryElements email={email} handleClose={handleClose} />
        <DrawerClose asChild>
          <div
            className="absolute right-16 top-16 flex size-32 items-center justify-center rounded-full bg-gray"
            onClick={handleClose}
          >
            <Icon iconName="close" className="text-black" />
          </div>
        </DrawerClose>
      </DrawerContent>
    </Drawer>
  ) : (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Content>
        <PasswordRecoveryElements email={email} handleClose={handleClose} />
      </Dialog.Content>
    </Dialog.Root>
  )
}

export const PasswordRecoveryElements = ({
  email,
  handleClose,
}: {
  email: string
  handleClose: () => void
}) => {
  return (
    <div className="flex h-auto flex-col">
      <div className="flex flex-col p-16">
        <h3 className="text-22 font-bold">
          <FormattedMessage id="emailSent" />
        </h3>
        <p className="mt-14 text-14">
          <FormattedMessage id="emailSentText" values={{ email }} />
        </p>

        <Button
          rounded="full"
          size="large"
          bg="blue-gradient"
          onClick={handleClose}
          className="mt-24"
        >
          <FormattedMessage id={'ok'} />
        </Button>
      </div>
    </div>
  )
}
