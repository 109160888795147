import { HttpClient } from './httpClient.ts'
import { PickPublic } from '../lib/utils.ts'
import { PracticeHintType } from './vocabApi.ts'
import {
  AgeKeys,
  InterestKeys,
  SexKeys,
  GoalKeys,
  ProfessionKeys,
  ImproveKeys,
  DurationKeys,
  LangLevels,
} from '../data/onboardingSteps.ts'
import { AppLanguages } from '../translations/messages.ts'

export type MessageStateHint =
  | 'hardcoded-question-two'
  | 'hardcoded-question-three'
  | 'hardcoded-question-four'

export type HintsType =
  | 'orthography_hint'
  | 'chat_onboarding_snowflake_hint'
  | 'show_positive_ortho'
  | 'start_vocabulary_hint'
  | null

export interface TrueOrFalseQuestion {
  title: string
  isCorrect: boolean
}

export interface ExerciseInfo {
  type: 'fill_the_gaps2' | 'match_words'
  exercise_count: number
}

export type SubscriptionPlan = '1M' | '3M' | '12M'
export type SubscriptionProvider = 'yakassa' | 'cp'

export type LessonType = 'lesson' | 'vocabulary' | null

export type ViewMode = 'readonly' | 'full'

export interface LessonStages {
  RU: Record<string, string>
  EN: Record<string, string>
  TR: Record<string, string>
  VI: Record<string, string>
}

export type SubscriptionStatus =
  | 'trial'
  | 'active'
  | 'inactive'
  | 'expired'
  | 'pending'
  | 'failed'
  | 'deleted'
export interface Subscription {
  id: number
  amount: number
  plan: SubscriptionPlan
  status: SubscriptionStatus
  subscription_date: string
  days: number
  days_estimated: number
  recurrent: boolean
  end: string | null
  start: string | null
}
export interface MatchWord {
  word: string
  synonym: string
}

type FillTheGapsText = readonly (string | { word: string })[]
export interface FillTheGaps2Data {
  title: { RU: string; EN: string }
  text: FillTheGapsText
  options: readonly string[]
  incorrect: { RU: string; EN: string }
  vocab_item: string
}

export type Listening = readonly {
  text: string
  sound_url: string
  options: readonly string[]
}[]
export type FillTheGaps = readonly (string | { word: string })[]
export type WriteTheGaps = readonly (string | { word: string; hint: string })[]
export const demoLessonTag = 'demo_lesson'

export const HelpLessonTag = (tag: string): boolean => {
  return tag.includes('Onboarding')
}

export interface Chat {
  id: number
  chat_title: string
  program_tag: string
  user: number
  next_program_tag: string | null
}

export interface Vocabulary {
  id: number
  word_original: string
  word_normal: string
  explain?: string
  context?: string
  translation?: string
  timestamp: string
  program_tag: string
  is_user_added: boolean
}

export interface ApiOnboardingInfo {
  goal: GoalKeys | ''
  profession: ProfessionKeys | ''
  interests: readonly InterestKeys[]
  sex: SexKeys | ''
  age: AgeKeys | ''
  improves: readonly ImproveKeys[]
  duration: DurationKeys | ''
  level: string
}

export interface Lesson {
  id: number
  title: string
  user: number
  message_id: number
}

type ProgramItemStatus = 'new' | 'active' | 'completed' | 'closed'
export interface ProgramItem {
  id: number
  level: string
  lesson: string
  tag: string
  program: string
  topic: string
  lesson_type: string
  lesson_name: string
  lesson_subject: string
  status: ProgramItemStatus
  order: number
  locked: boolean | null
  ai_rate?: {
    rate?: number
  }
}

export interface AiCommentSpellElement {
  text: string
  rule: string
  correct: string
}

export interface AiComment {
  spell: readonly AiCommentSpellElement[]
  hint?: HintsType
}

export interface RateAiComment {
  rate: 1 | 2 | 3
  points?: { stage_points: number; exercise_points: number }
}

export type InfoBlockType =
  | 'start_vocabulary_hint'
  | 'first_message'
  | 'orthography_hint'
  | 'disable_hints'
  | 'show_positive_ortho'

export interface IExercises {
  currentIndex: number
  exercise_count: number
  exercises: readonly ExerciseInfo[] | null
}

export interface ChatMessage {
  id: number
  message_text: string
  is_ai: boolean
  picture_url?: string
  listening?: Listening
  fill_the_gaps?: readonly FillTheGaps[]
  fill_the_gaps2?: readonly FillTheGaps2Data[]
  article?: {
    body: string
    header: string
  }
  true_or_false?: readonly TrueOrFalseQuestion[]
  write_the_gaps?: readonly WriteTheGaps[]
  match_words?: readonly MatchWord[]
  ai_comment?: AiComment
  ai_rate?: RateAiComment | null
  picture_urls?: readonly { url: string; title: string }[]
  parent_msg_id?: number
  info_block?: InfoBlockType | 'none'
  is_last: boolean
  is_hidden?: boolean
  translation?: string
  reply_hint?: {
    text: string
    translation: string
  }
  state?: string
  exercises?: IExercises
  contexts?: readonly string[]
}

export interface BalanceResponse {
  value: number
}

export interface SessionMetadata {
  programTag: string
  lessonType: LessonType
  hints?: PracticeHintType[]
}

export interface Student {
  name: string
  level: LangLevels
  onboarding_info: ApiOnboardingInfo
  phone_number: string
  phone_confirmed: boolean
  active: boolean
  date_joined?: string | null
  last_login?: string | null
  session_metadata?: SessionMetadata | null
}

export interface Stat {
  total: number
  new: number
  active?: number
  completed?: number
  vocab: { program: string; learned_count: number; total_count: number }[]
}

export interface UpdateStudent {
  name?: string
  level?: string
  onboarding_info?: ApiOnboardingInfo
}

export interface PatchStudent {
  name?: string
  level?: string
  onboarding_info?: ApiOnboardingInfo
  ui_lang?: AppLanguages
  ui_device?: 'mobile' | 'desktop'
  session_metadata?: SessionMetadata | null
}

export interface UpdatePassword {
  password: string
}

export interface UpdateEmail {
  email: string
}

export interface CloudPaymentsPaymentData {
  publicId: string
  description: string
  amount: number
  currency: string
  invoiceId: string
  accountId: string
  email: string
  skin: string
  data: {
    cloudPayments: {
      recurrent?: {
        interval: string
        period: number
      }
    }
  }
}

export interface YaKassaPaymentData {
  confirmation_token: string
}

export type Hints =
  | 'marketingPracticeHint'
  | 'marketingLearningProfileHint'
  | 'lessonsOnboarding'
  | 'practiceOnboarding'
  | 'chatOnboarding'
  | 'learningProfileOnboarding'

export type AccountHints = Record<Hints, boolean>

function updateProgram(item: ProgramItem) {
  item.program = item.program
  item.topic = item.topic
  return item
}

export type ChatApi = PickPublic<AppChatApi>
export class AppChatApi {
  async userPrograms(): Promise<ProgramItem[]> {
    const url = '/api/user_programs/'
    return this.httpClient
      .get<ProgramItem[]>(url)
      .then((x) => x.map(updateProgram))
  }

  constructor(private httpClient: HttpClient) {}

  async version() {
    return this.httpClient.get<string>('/api/version/')
  }

  async unlockLesson(tag: string) {
    return this.httpClient.patch<never>(`/api/user_program/lock/${tag}/`, {
      locked: false,
    })
  }

  async chat_list() {
    return this.httpClient.get<Chat[]>('/api/chat_list/')
  }

  chat_create(request: {
    user: number
    chat_title: string
    program_tag: string
    language?: 'RU' | 'EN'
    recreate?: boolean
    parameters?: { user_vocab: string[] }
  }) {
    return this.httpClient.post<Chat>('/api/chat_create/', request)
  }

  message_create(request: { chat: number; message_text: string }) {
    return this.httpClient.post<ChatMessage>('/api/message_create/', request)
  }

  message_process(messageId: number) {
    return this.httpClient.get<ChatMessage[]>(
      `/api/message/${messageId}/process/`,
    )
  }

  message_translate(messageId: number) {
    return this.httpClient.get<ChatMessage>(
      `/api/message/${messageId}/translate/`,
    )
  }

  message_reply_hint(messageId: number) {
    return this.httpClient.get<ChatMessage>(
      `/api/message/${messageId}/reply_hint/`,
    )
  }

  message_orthography_check(messageId: number) {
    return this.httpClient.get<ChatMessage>(
      `/api/message/${messageId}/orthography/`,
    )
  }

  async message_list(chatId: number): Promise<ChatMessage[]> {
    const response = await this.httpClient.get<ChatMessage[]>(
      `/api/message_list/${chatId}/`,
    )
    return response.map((x) => ({ ...x, is_ai: Boolean(x.is_ai) }))
  }

  async lesson_list(chatId: number): Promise<Lesson[]> {
    return await this.httpClient.get<Lesson[]>(`/api/lesson_list/${chatId}/`)
  }

  async message(messageId: number): Promise<ChatMessage> {
    return await this.httpClient.get<ChatMessage>(`/api/message/${messageId}/`)
  }

  async balance() {
    return await this.httpClient.get<BalanceResponse>(`/api/balance/`)
  }

  async getAccount() {
    return await this.httpClient.get<Student>(`/api/account/`)
  }

  async userProgramStages(tag: string) {
    return await this.httpClient.get<LessonStages>(
      `/api/user_program/stages/${tag}/`,
    )
  }

  async userProgramStage(tag: string) {
    return await this.httpClient.get<{ stage: string }>(
      `/api/user_program/stage/${tag}/`,
    )
  }

  async userProgramLastLesson() {
    const url = '/api/user_programs/active/last/'

    return await this.httpClient.get<ProgramItem>(url)
  }

  async updateAccount(update: UpdateStudent) {
    return await this.httpClient.put<Student>(`/api/account/`, update)
  }

  async patchAccount(update: PatchStudent) {
    return await this.httpClient.patch<Student>(`/api/account/`, update)
  }

  async deleteAccount() {
    await this.httpClient.del<never>(`/api/account/`, {})
  }

  async patchMessage(messageId: number, update: { duration_front: number }) {
    return await this.httpClient.patch<never>(
      `/api/message/${messageId}/`,
      update,
    )
  }

  async getHints() {
    return await this.httpClient.get<AccountHints>('/api/account/hints/')
  }

  async deleteHint(hint: Hints) {
    return await this.httpClient.patch<AccountHints>(
      `/api/account/hint/${hint}/`,
      { flag: false },
    )
  }

  async updatePassword(update: UpdatePassword) {
    await this.httpClient.post<never>('/api/change_password/', update)
  }

  async updateEmail(update: UpdateEmail) {
    await this.httpClient.post<never>('/api/change_email/', update)
  }

  async stat() {
    return await this.httpClient.get<Stat>(`/api/account/stat/`)
  }

  async voice_push(chatId: number, file: Blob) {
    //return Promise.resolve({done: true})
    const formData = new FormData()
    formData.append('file', file)
    return this.httpClient.request<{ done: boolean }>(
      '/api/voice_push/' + chatId + '/',
      {
        method: 'POST',
        body: formData,
        // headers: {
        //   'Content-Disposition': 'attachment; filename=file',
        //   'Content-Type': 'audio/wave',
        // },
      },
    )
  }

  vocabulary_add(request: {
    chat_id: number
    word_original: string
    message_id: number
  }) {
    return this.httpClient.post<Vocabulary>(
      `/api/vocabulary/chat/${request.chat_id}/add/`,
      request,
    )
  }
  vocabulary_delete(vocabularyId: number) {
    return this.httpClient.del<never>(
      `/api/vocabulary/remove/${vocabularyId}/`,
      {},
    )
  }

  vocabulary(tag: string) {
    return this.httpClient.get<Vocabulary[]>(`/api/vocabulary/${tag}`)
  }

  message_rate(id: number, rating: number, user_comment?: string) {
    return this.httpClient.patch<never>(`/api/message_rate/${id}/`, {
      rating,
      user_comment,
    })
  }

  chat_rate(
    id: number,
    rating?: number,
    experience?: number,
    user_comment?: string,
  ) {
    return this.httpClient.patch<never>(`/api/chat_rate/${id}/`, {
      rating,
      experience,
      user_comment,
    })
  }

  subscribe(plan: SubscriptionPlan, provider: SubscriptionProvider) {
    return this.httpClient.post<
      | {
          status: 'success'
          payment_data: CloudPaymentsPaymentData | YaKassaPaymentData
          success_url: string
        }
      | {
          status: 'rejected'
          message: string
        }
    >('/api/subscribe/', { plan, provider })
  }

  subscribe_trial(plan: SubscriptionPlan, provider: SubscriptionProvider) {
    return this.httpClient.post<
      | {
          status: 'success'
          payment_data: CloudPaymentsPaymentData | YaKassaPaymentData
          success_url: string
        }
      | {
          status: 'rejected'
          message: string
        }
    >('/api/subscribe_trial/', { plan, provider })
  }

  unsubscribe(
    subscriptionId: number,
    feedback?: { reason: string[]; comment: string; contacts: string },
  ) {
    return this.httpClient.post<{ status: 'success' | 'reject' }>(
      `/api/iap/${subscriptionId}/unsubscribe/`,
      { feedback },
    )
  }

  subscriptionsList() {
    return this.httpClient.get<Subscription[]>(`/api/iap/`)
  }

  async createSubscriptions(
    plan: SubscriptionPlan,
    provider: SubscriptionProvider,
  ) {
    return await this.httpClient.post<null>(`/api/iap/`, { plan, provider })
  }

  async updateSubscriptions(update: Subscription) {
    return await this.httpClient.put<Subscription>(
      `/api/iap/${update.id}/`,
      update,
    )
  }

  async deleteSubscriptions(update: Subscription) {
    return await this.httpClient.del<Subscription>(
      `/api/iap/${update.id}/`,
      update,
    )
  }
  async logDevice(userId: number, deviceType: string) {
    await this.httpClient.post(
      '/api/log_device/',
      {
        user_id: userId,
        device_type: deviceType,
      },
      true,
    )
  }
}
