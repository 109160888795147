import { cn } from '../../../shared/lib/utils.ts'
import { Icon } from '../icon/icon.tsx'

const Checkbox = ({
  className,
  checked,
  handler,
}: {
  className: string
  checked: boolean
  handler?: (e: React.MouseEvent<HTMLDivElement>) => void
}) => {
  return (
    <div
      className={cn(
        'flex size-20 cursor-pointer items-center justify-center rounded-4 border-2 transition duration-200',
        checked ? 'border-main bg-main' : 'border-main bg-white',
        className,
      )}
      onClick={handler}
    >
      <Icon iconName="tick" size="xs" className="text-white" />
    </div>
  )
}

export default Checkbox
