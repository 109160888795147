export const handleSetSpotlightHeight = (headerId: string, delay?: number) => {
  const header = document.getElementById(headerId)
  const spotlight = document.querySelector('.react-joyride__spotlight')

  if (!(header instanceof HTMLElement) || !(spotlight instanceof HTMLElement))
    return

  setTimeout(() => {
    const headerRect: DOMRect = header.getBoundingClientRect()
    const spotlightStyle: CSSStyleDeclaration = spotlight.style

    if (typeof headerRect.top === 'number') {
      const height = `${window.innerHeight - headerRect.top - 92}px`
      spotlightStyle.height = height
    }
  }, delay ?? 100)
}
