import { ChatApi, Subscription } from '../../shared/api/chatApi.ts'
import { none, taggedUnion, type } from '../../shared/lib/taggedUnion.ts'
import { AuthStore } from '../auth/authStore.ts'

// const SoonExpiredDaysCount = 5

export const SubscriptionState = taggedUnion({
  inactive: none,
  active: none,
  soonExpired: type<{ date: Date }>(),
})
export type SubscriptionState = typeof SubscriptionState.type
export class SubscriptionService {
  private successfulStatus = ['active', 'trial']
  private failedStatus = ['failed', 'expired', 'inactive', 'virtual', 'pending']
  private virtualStatus = []

  constructor(
    private chatApi: ChatApi,
    private authStore: AuthStore,
  ) {}

  async getActiveSubscription() {
    const subscriptions = await this.chatApi.subscriptionsList()
    this.setSubscribeState(subscriptions)
    return subscriptions.find((x) => this.successfulStatus.includes(x.status))
  }

  setSubscribeState(subscriptions: Subscription[]) {
    subscriptions.find((x) => this.failedStatus.includes(x.status)) &&
      this.authStore.setSubscribeState(false)
    subscriptions.find((x) => this.successfulStatus.includes(x.status)) &&
      this.authStore.setSubscribeState(true)
  }

  async getSubscriptionState(): Promise<SubscriptionState> {
    if (this.authStore.state.isAnon) {
      return SubscriptionState.active()
    }
    const activeSubscription = await this.getActiveSubscription()
    if (activeSubscription) {
      return SubscriptionState.active()
      // return !activeSubscription.recurrent &&
      //   activeSubscription.days_estimated <= SoonExpiredDaysCount
      //   ? SubscriptionState.soonExpired({
      //       date: addDays(
      //         activeSubscription.subscription_date,
      //         activeSubscription.days,
      //       ),
      //     })
      //   : SubscriptionState.active()
    }
    return SubscriptionState.inactive()
  }
}
