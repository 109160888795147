import { classed } from '@tw-classed/react'
import { IMetricCard, metricCards } from '../../shared/data/progressMetrics.ts'
import { FormattedMessage } from 'react-intl'
import { useAppModule } from '../appContext.ts'
import { useSnapshot } from 'valtio'
import { format } from 'date-fns'
import MetricCard from '../../shared/ui/metricCard/metricCard.tsx'
import {
  IMetrics,
  cn,
  getProgressWordsMetricsDate,
} from '../../shared/lib/utils.ts'
import { IGraphElement } from '../../shared/api/practiceApi.ts'
import { AppLanguages } from '../../shared/translations/messages.ts'

export interface IProgressPageHeaderProps {
  username: string | undefined
  dateJoined: string | undefined | null
}

export interface IMetricCardsProps {
  metrics: IMetrics[]
}

export const ProgressPageHeader = classed.h1(
  'text-center text-18 font-bold text-black',
)

export const MetricsCards = () => {
  const progressStore = useAppModule().progressStore
  const { metrics } = useSnapshot(progressStore.state)

  return (
    <div className="mt-18 flex h-fit min-h-136 gap-10">
      {metricCards.map((el: IMetricCard) => {
        //eslint-disable-next-line
        const metricValue = metrics[el.metricKey]?.[0]?.value

        return <MetricCard key={el.key} value={metricValue} element={el} />
      })}
    </div>
  )
}

export const ProgressPageHeaderData = ({
  username,
  dateJoined,
}: IProgressPageHeaderProps) => {
  return (
    <>
      <h3 className="text-center text-24 font-bold text-black">
        {username ? username : 'Student'}
      </h3>
      {!!dateJoined && (
        <p className="text-center text-12 font-medium text-gray4">
          <FormattedMessage
            id="metrics.joinedDate"
            values={{
              date: format(new Date(dateJoined), 'dd.MM.yyyy '),
            }}
          />
        </p>
      )}
    </>
  )
}

export const ProgressMetricsWrapper = ({
  graph,
  wordsCount,
}: {
  graph: IGraphElement[]
  wordsCount: number
}) => {
  const appStore = useAppModule().appStore
  const state = useSnapshot(appStore.state)

  return (
    <div className="mt-42 flex w-full flex-col rounded-18 bg-gray-light px-18 pb-18 pt-24">
      <div className="flex justify-between">
        <div className="text-start">
          <p className="text-18 font-bold">
            <FormattedMessage
              id="wordsPluralInVocab"
              values={{ count: wordsCount }}
            />
          </p>
          <p className="text-12 font-bold text-gray8">
            {getProgressWordsMetricsDate(
              graph[graph.length - 1].range_start,
              graph[0].range_end,
              state.language,
              false,
            )}
          </p>
        </div>
        {/* <Switcher /> */}
      </div>
      <div className="mt-24 flex flex-col gap-12">
        {graph.length &&
          graph.map((el, i) => (
            <WordsMetricElement
              elementInfo={el}
              lang={state.language}
              key={`metric-word-bar-${i}`}
            />
          ))}
      </div>
    </div>
  )
}

// const SwitcherElement = classed.div(
//   'px-16 py-4 rounded-full  transition-all flex items-center justify-center text-16',
//   {
//     variants: {
//       active: { true: 'bg-black text-white', false: 'bg-white text-black' },
//     },
//   },
// )

// export const Switcher = () => {
//   const [isActive, setIsActive] = useState(false)

//   return (
//     <div className="flex bg-white rounded-full h-34">
//       <SwitcherElement active={!isActive} onClick={() => setIsActive(false)}>
//         <FormattedMessage id="practice.strong" />
//       </SwitcherElement>
//       <SwitcherElement onClick={() => setIsActive(true)} active={isActive}>
//         <FormattedMessage id="practice.all" />
//       </SwitcherElement>
//     </div>
//   )
// }

export const WordsMetricElement = ({
  elementInfo,
  lang,
}: {
  elementInfo: IGraphElement
  lang: AppLanguages
}) => {
  return (
    <div className="flex w-full flex-col gap-2">
      <div className="flex justify-between px-8">
        <p className="text-10 font-semibold text-gray8">
          {getProgressWordsMetricsDate(
            elementInfo.range_start,
            elementInfo.range_end,
            lang,
          )}
        </p>
        <p className="text-12 font-extrabold text-message-button">
          +{elementInfo.delta}
        </p>
      </div>
      <div className="h-24 w-full overflow-hidden rounded-full bg-white">
        <div
          className={cn(
            'h-full rounded-full bg-blue2',
            elementInfo.ratio ? 'w-full min-w-36' : 'w-0',
          )}
          style={{ width: `${elementInfo.ratio * 100}%` }}
        />
      </div>
    </div>
  )
}
