import { formatDate, parse } from 'date-fns'

export const isValidDateFormat = (dateString: string): boolean => {
  if (dateString.length !== 10) return false

  const pattern = /^\d{2}\/\d{2}\/\d{4}$/
  return pattern.test(dateString)
}

export const formatSubscriptionDate = (dateString: string): string => {
  try {
    const date = parse(dateString, 'dd/MM/yyyy', new Date())
    return formatDate(date, 'dd.MM.yyyy')
  } catch (e) {
    console.error('Error parsing date:', e)
    return ''
  }
}
