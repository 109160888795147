import { JoyrideCustomTooltip } from '../ui/joyrideTooltip/JoyrideCustomTooltip.tsx'

export const DESKTOP_PROGRAM_TOUR_STEPS = [
  {
    target: '#lesson-joyride-0',
    content: (
      <span>
        <span>Давайте скорее начинать 🙂</span>
        <br />
        <span className="font-extrabold">Нажмите на урок!</span>
      </span>
    ),
    disableBeacon: true,
    placement: 'top' as const,
    spotlightPadding: 8,
    imagePlace: 'right',
    disableButton: true,
    disableOverlayClose: true,
    styles: {
      tooltip: {
        width: '300px',
        padding: '16px !important',
      },
    },
  },
]

export const PROGRAM_TOUR_STEPS = [
  {
    target: '#level-select-button',
    content:
      'Приветствую! Я помогу вам на первых шагах ☺️ Начнем на этом уровне! 🚀',
    disableBeacon: true,
    placement: 'bottom' as const,
    styles: {
      tooltip: {
        width: '300px',
      },
    },
    imagePlace: 'bottom',
    disableButton: false,
  },
  {
    target: '#lessons-units',
    content:
      'На каждом уровне много интересных тем от искусства до технологий. Выбирайте темы здесь.',
    disableBeacon: true,
    placement: 'bottom' as const,
    spotlightPadding: 0,
    styles: {
      spotlight: {
        transform: 'translate(8px, -8px)',
        maxWidth: 'calc(100vw - 16px)',
      },
      tooltip: {
        maxWidth: 'calc(100vw - 32px)',
        width: '300px',
      },
    },
    imagePlace: 'bottom',
    disableButton: false,
  },
  {
    target: '#units-0-header',
    content:
      'Здесь располагаются сами уроки. Будем много читать, слушать и говорить.',
    disableBeacon: false,
    placement: 'top' as const,
    spotlightPadding: 8,
    styles: {},
    imagePlace: 'top-right',
    disableButton: false,
  },
  {
    target: '#lesson-joyride-0',
    content: (
      <span>
        <span>Давайте скорее начинать 🙂</span>
        <br />
        <span className="font-extrabold">Нажмите на урок!</span>
      </span>
    ),
    disableBeacon: false,
    placement: 'top' as const,
    spotlightPadding: 8,
    imagePlace: 'top-right',
    disableButton: true,
    disableOverlayClose: true,
  },
]

export const PRACTICE_TOUR_STEPS = [
  {
    target: '#progress-bar',
    content:
      'Вжух! И мы с Словарике+. Тренируйте свои слова, чтобы они стали сильными.',
    disableBeacon: true,
    placement: 'top' as const,
    styles: {
      tooltip: {
        width: '300px',
      },
      tooltipContainer: {
        padding: '24px',
      },
      spotlight: {
        minHeight: '188px',
      },
    },
    spotlightPadding: 8,
    imagePlace: 'top-right',
    disableButton: false,
  },
  {
    target: '#practice-lesson-header-quick',
    content: 'Шесть классных игр помогут вам точно запомнить все слова!',
    disableBeacon: true,
    placement: 'top' as const,
    spotlightPadding: 8,
    imagePlace: 'top-right',
    disableButton: false,
    styles: {
      tooltip: {
        width: '300px',
      },
      tooltipContainer: {
        padding: '24px',
      },
      spotlight: {
        minHeight: '60px',
      },
    },
    floaterProps: {
      offset: 24,
      styles: {
        container: {
          transform: 'translateY(-40px)',
        },
      },
    },
  },
  {
    target: '#vocab-lesson-0',
    content: (
      <span>
        Начнем с Match Game!{' '}
        <span className="font-extrabold">Нажмите на урок!</span>
      </span>
    ),
    disableBeacon: false,
    placement: 'top' as const,
    spotlightPadding: 8,
    imagePlace: 'top-right',
    disableButton: true,
    styles: {
      tooltip: {
        width: '300px',
      },
      tooltipContainer: {
        padding: '24px',
      },
      spotlight: {
        minHeight: '60px',
      },
    },
    floaterProps: {
      offset: 24,
      styles: {
        container: {
          transform: 'translateY(-40px)',
        },
      },
    },
  },
]

export const joyrideProps = {
  continuous: true,
  showSkipButton: false,
  hideCloseButton: true,
  hideBackButton: true,
  showProgress: false,
  disableOverlayClose: true,
  spotlightPadding: 8,
  disableScrollParentFix: false,
  disableCloseOnEsc: true,
  disableScrolling: false,
  scrollOffset: 110,
  locale: {
    next: 'Дальше',
    last: 'Дальше',
  },
  styles: {
    spotlight: {
      borderRadius: 22,
    },
    tooltip: {
      borderRadius: 16,
      paddingBottom: 32,
      padding: 16,
    },
  },
  spotlightClicks: false,
  tooltipComponent: JoyrideCustomTooltip,
}
