import { useIntl } from 'react-intl'
import { AppMessageKeys } from '../../shared/translations/messages.ts'
import {
  DurationKeys,
  AgeKeys,
  GoalKeys,
  ImproveKeys,
  LangLevels,
  LanguageLevelKeys,
  ProfessionKeys,
  InterestKeys,
  SexKeys,
  languageLevelData,
} from '../../shared/data/onboardingSteps.ts'

export type OnboardingItemType = readonly [
  string,
  AppMessageKeys,
  OnboardingKeysType | number,
]
export type OnboardingKeysType =
  | LanguageLevelKeys
  | ImproveKeys
  | GoalKeys
  | ProfessionKeys
  | AgeKeys
  | DurationKeys
  | InterestKeys
  | SexKeys
  | string

export type ChooseListData = readonly [
  string,
  string,
  OnboardingKeysType | number,
]

export interface OnboardingData {
  duration: readonly ChooseListData[]
  profession: readonly ChooseListData[]
  goal: readonly ChooseListData[]
  improves: readonly ChooseListData[]
  languageLevel: readonly ChooseListData[]
  sex: readonly ChooseListData[]
  interests: readonly ChooseListData[]
  age: readonly ChooseListData[]
  levels: readonly LangLevels[]
}

function useIntlItems(items: readonly OnboardingItemType[]) {
  const intl = useIntl()
  return items.map((x) => {
    const text = intl.formatMessage({ id: x[1] })
    return [x[0], text, x[2]] as ChooseListData
  })
}

export function useOnboardingData(): OnboardingData {
  const ageData: readonly OnboardingItemType[] = [
    ['👶', 'onboarding.age-1', AgeKeys.Under18],
    ['🎓', 'onboarding.age-2', AgeKeys.Teenager],
    ['💼', 'onboarding.age-3', AgeKeys.Adult],
    ['🏡', 'onboarding.age-4', AgeKeys.MiddleAged],
    ['🌟', 'onboarding.age-5', AgeKeys.Senior],
    ['🎉', 'onboarding.age-6', AgeKeys.Elderly],
    ['🙈', 'onboarding.noanswer', AgeKeys.NoAnswer],
  ]

  const age = useIntlItems(ageData)

  const intl = useIntl()
  const durations: readonly OnboardingItemType[] = [
    ['⏱️', 'minutes', DurationKeys.Five],
    ['⏱️', 'minutes', DurationKeys.Ten],
    ['⏱️', 'minutes', DurationKeys.Fifteen],
    ['⏱️', 'minutes', DurationKeys.Thirty],
    ['⏱️', 'minutes', DurationKeys.Forty],
    ['⏱️', 'minutes', DurationKeys.Sixty],
  ]
  const duration = durations.map((d) => {
    const text = intl.formatMessage({ id: 'minutes' }, { count: Number(d[2]) })
    return [d[0], text, d[2]] as const
  })

  const improveData: readonly OnboardingItemType[] = [
    ['🗣️', 'onboarding.pronunciation', ImproveKeys.Pronunciation],
    ['📖', 'onboarding.vocabulary', ImproveKeys.Vocabulary],
    ['✍️', 'onboarding.grammar', ImproveKeys.Grammar],
    ['📚', 'onboarding.reading', ImproveKeys.Reading],
    ['📝', 'onboarding.writing', ImproveKeys.Writing],
    ['💬', 'onboarding.speaking', ImproveKeys.Speaking],
    ['👂', 'onboarding.listening', ImproveKeys.Listening],
  ]

  const improves = useIntlItems(improveData)

  const sexData: readonly OnboardingItemType[] = [
    ['👨', 'onboarding.male', SexKeys.Male],
    ['👩', 'onboarding.female', SexKeys.Female],
    ['🙈', 'onboarding.noanswer', SexKeys.NoAnswer],
  ]

  const sex = useIntlItems(sexData)

  const goalData: readonly OnboardingItemType[] = [
    ['💼', 'onboarding.goal-1', GoalKeys.DreamJob],
    ['🏘️', 'onboarding.goal-2', GoalKeys.ForeignCountry],
    ['📚', 'onboarding.goal-3', GoalKeys.MoviesAndBooks],
    ['🌍', 'onboarding.goal-4', GoalKeys.Travel],
    ['💬', 'onboarding.goal-5', GoalKeys.Speaking],
  ] as const

  const goal = useIntlItems(goalData)

  const interestData: readonly OnboardingItemType[] = [
    ['🍔', 'onboarding.food', InterestKeys.Food],
    ['🎨', 'onboarding.fineArts', InterestKeys.FineArts],
    ['🔬', 'onboarding.science', InterestKeys.Science],
    ['⚽', 'onboarding.sports', InterestKeys.Sports],
    ['🌿', 'onboarding.nature', InterestKeys.Nature],
    ['✈️ ', 'onboarding.travel', InterestKeys.Travel],
    ['🏡', 'onboarding.homeAndDecor', InterestKeys.HomeAndDecor],
    ['🎵', 'onboarding.music', InterestKeys.Music],
    ['🩺', 'onboarding.health', InterestKeys.Health],
    ['🛍️', 'onboarding.shopping', InterestKeys.Shopping],
    ['🧠', 'onboarding.psychology', InterestKeys.Psychology],
    ['🎥', 'onboarding.cinema', InterestKeys.Cinema],
    ['🐶', 'onboarding.pets', InterestKeys.Pets],
    ['👗', 'onboarding.fashion', InterestKeys.Fashion],
    ['🖥️', 'onboarding.technologies', InterestKeys.Technologies],
    ['🎮', 'onboarding.games', InterestKeys.Games],
    ['🎓', 'onboarding.education', InterestKeys.Education],
    ['🎭', 'onboarding.theater', InterestKeys.Theater],
    ['🥘', 'onboarding.culinaryArts', InterestKeys.CulinaryArts],
    ['💰', 'onboarding.finance', InterestKeys.Finance],
    ['📜', 'onboarding.history', InterestKeys.History],
    ['📸', 'onboarding.photography', InterestKeys.Photography],
  ] as const

  const interests = useIntlItems(interestData)

  const levelDataFromStructure: readonly OnboardingItemType[] =
    languageLevelData.map((item) => [item.icon, item.displayKey, item.key])

  const languageLevel = useIntlItems(levelDataFromStructure)
  const levels = languageLevelData.map((item) => item.langLevel)

  const professionData: OnboardingItemType[] = [
    ['📈', 'onboarding.marketer', ProfessionKeys.Marketer],
    ['🔬', 'onboarding.researcher', ProfessionKeys.Researcher],
    ['👩‍🏫', 'onboarding.teacher', ProfessionKeys.Teacher],
    ['🤝', 'onboarding.salesManager', ProfessionKeys.SalesManager],
    ['👥', 'onboarding.hrSpecialist', ProfessionKeys.HRSpecialist],
    ['💻', 'onboarding.itSpecialist', ProfessionKeys.ITSpecialist],
    [
      '📣',
      'onboarding.publicRelationsSpecialist',
      ProfessionKeys.PublicRelationsSpecialist,
    ],
    [
      '✍️',
      'onboarding.contentWriterEditor',
      ProfessionKeys.ContentWriterEditor,
    ],
    ['⚖️', 'onboarding.lawyer', ProfessionKeys.Lawyer],
    ['🧮', 'onboarding.accountant', ProfessionKeys.Accountant],
    ['💼', 'onboarding.entrepreneur', ProfessionKeys.Entrepreneur],
    ['👨‍', 'onboarding.softwareDeveloper', ProfessionKeys.SoftwareDeveloper],
    ['🧠', 'onboarding.psychologist', ProfessionKeys.Psychologist],
    ['👩‍⚕️', 'onboarding.nurse', ProfessionKeys.Nurse],
    ['🏠', 'onboarding.realEstateAgent', ProfessionKeys.RealEstateAgent],
    ['📐', 'onboarding.architect', ProfessionKeys.Architect],
    ['🤷', 'onboarding.other', ProfessionKeys.Other],
  ]

  const profession = useIntlItems(professionData)

  return {
    age,
    duration,
    improves,
    sex,
    goal,
    interests,
    levels,
    languageLevel,
    profession,
  }
}
