import React, { useLayoutEffect, useEffect, useMemo, useState } from 'react'
import { useAppModule } from '../features/appContext.ts'
import { isWebDomain, urls } from '../shared/urls.ts'
import { Navigate, useNavigate, useLocation } from 'react-router-dom'
import { useOnboardingStore } from '../features/onboarding/OnboardingView.tsx'
import { useSnapshot } from 'valtio/react'
import { AppLocalStorage } from '../shared/lib/appLocalStorage.ts'

export function AuthAccess(props: { children: React.ReactNode }) {
  const authStore = useAppModule().authStore
  const location = useLocation()

  const [isLoading, setIsLoading] = useState(true)

  const getNavigate = () => {
    if (!authStore.isLogin()) {
      const url = isWebDomain ? urls.chooseyourlevel : urls.login
      return <Navigate to={url} />
    }

    if (!authStore.isConfirm()) {
      return <Navigate to={urls.confirm} />
    }

    if (authStore.state.isAnon) {
      return <Navigate to={urls.onboarding()} />
    }

    if (location.pathname == urls.login) {
      return <Navigate to={urls.login} />
    }

    if (
      authStore.isLogin() &&
      authStore.isPayer() &&
      !authStore.getActiveStudent()
    ) {
      return <Navigate to={urls.subscriptions()} />
    }

    return props.children
  }

  useLayoutEffect(() => {
    const studentActive = async () => {
      if (authStore.isLogin()) {
        try {
          await authStore.getStudent()
        } catch (e) {
          console.log('Error get student:', e)
        } finally {
          setIsLoading(false)
        }
      } else {
        setIsLoading(false)
      }
    }
    void studentActive()
  }, [authStore])

  return <>{!isLoading && getNavigate()}</>
}

export function DemoAccess(props: { children: React.ReactNode }) {
  const authStore = useAppModule().authStore
  const onboardingStore = useOnboardingStore()
  const onboardingState = useSnapshot(onboardingStore.state)
  if (!authStore.isLogin()) {
    return <Navigate to={urls.onboarding()} />
  }
  if (
    authStore.isLogin() &&
    authStore.isConfirm() &&
    !authStore.state.isAnon &&
    !onboardingState.goToDemo
  ) {
    return <Navigate to={urls.learningProfile()} />
  }
  onboardingStore.resetGoToDemo()
  return props.children
}

export function LoginAccess(props: { children: React.ReactNode }) {
  const { authStore } = useAppModule()
  const location = useLocation()
  const isCurrentPaywallPage =
    location.pathname == urls.subscriptions('paywall') ||
    location.pathname == urls.subscriptions('card-added')
  const isCurrentEmailPage = location.pathname == urls.subscriptions('email')
  const isCurrentSubscriptionsPage = location.pathname == urls.subscriptions()
  const isCurrentLoginPage = location.pathname == urls.login

  ///const isOnboardingCompleted = appSessionStorage.get('onboarding')?.completed

  if (
    authStore.isLogin() &&
    !authStore.state.isAnon &&
    !isCurrentPaywallPage &&
    !isCurrentEmailPage &&
    !isCurrentSubscriptionsPage &&
    !isCurrentLoginPage
  ) {
    return <Navigate to={urls.learningProfile()} />
  }

  return props.children
}

export function ConfirmAccess(props: { children: React.ReactNode }) {
  const authStore = useAppModule().authStore

  if (authStore.isLogin() && !authStore.state.isAnon && authStore.isConfirm()) {
    return <Navigate to={urls.learningProfile()} />
  }

  return props.children
}

export function SubscriptionsAccess(props: { children: React.ReactNode }) {
  const authStore = useAppModule().authStore
  const subscriptionService = useAppModule().subscriptionService()
  const onboardingStore = useOnboardingStore()
  const onboardingState = useSnapshot(onboardingStore.state)
  const navigate = useNavigate()

  useLayoutEffect(() => {
    const checkSubscriptions = async () => {
      try {
        const response = await subscriptionService.getActiveSubscription()
        if (response) {
          navigate(urls.learningProfile(), { replace: true })
        }
      } catch {}
    }

    void checkSubscriptions()
  }, [subscriptionService, navigate])

  if (!authStore.isLogin() || !onboardingState.completed) {
    return <Navigate to={urls.onboarding()} />
  }

  if (
    authStore.isLogin() &&
    !authStore.state.isAnon &&
    !authStore.isConfirm()
  ) {
    return <Navigate to={urls.confirm} />
  }

  if (
    authStore.isLogin() &&
    !authStore.state.isAnon &&
    authStore.isConfirm() &&
    onboardingState.hasSubscription
  ) {
    return <Navigate to={urls.learningProfile()} />
  }

  return props.children
}

export function RouteSaver(props: { children: React.ReactNode }) {
  const location = useLocation()
  const appStorage = useMemo(() => new AppLocalStorage(localStorage), [])

  useEffect(() => {
    appStorage.set('lastRoute', { url: location.pathname })
  }, [location, appStorage])

  return props.children
}
