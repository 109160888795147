import { useNavigate } from 'react-router-dom'
import { ChatStore } from '../chats/chatStore.ts'
import { VocabLessonStore } from '../vocabLessons/vocabLessonStore.ts'
import { useSnapshot } from 'valtio'
import { urls } from '../../shared/urls.ts'
import { AnimatePresence } from 'framer-motion'
import { FormattedMessage } from 'react-intl'
import {
  IMetricCard,
  lessonMetricCards,
} from '../../shared/data/progressMetrics.ts'
import { MetricKeysEnum, cn, getValueByIndex } from '../../shared/lib/utils.ts'
import MetricCard from '../../shared/ui/metricCard/metricCard.tsx'
import { BottomButtons } from '../../shared/ui/bottomButtons.tsx'
import {
  ADVANCED_LESSON_TAGS,
  ADVANCED_LESSON_WITH_CHAT,
} from '../../shared/data/practice.ts'
import { useIsMobile } from '../../shared/lib/hooks.ts'
export const LessonStatistics = ({
  store,
}: {
  store: ChatStore | VocabLessonStore
}) => {
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  // const store = useStores().chatStore
  const _state = useSnapshot(store.state)
  const metrics = _state.statistics.metrics
  const lessonType = _state.lessonType
  const isVocabLesson =
    lessonType === 'vocabulary' ||
    _state.programTag === ADVANCED_LESSON_WITH_CHAT
  const programTag = _state.programTag
  const isAdvancedLesson = ADVANCED_LESSON_TAGS.includes(programTag)
  const isHelpLesson = _state.isHelpLesson
  const isHelpVocabLesson = _state.isHelpVocabLesson

  const handleContinue = () => {
    if (isHelpVocabLesson) {
      navigate(urls.learningProfile(), {
        state: { showOnboardingHint: true },
      })
    } else {
      navigate(
        isVocabLesson
          ? urls.practice
          : isHelpLesson && isMobile
          ? urls.practice
          : urls.program,
      )
    }
    store.resetLessonStat()
  }

  const handleRepeat = () => {
    if (isAdvancedLesson) {
      navigate(urls.practiceWords('select', programTag))
    } else {
      void store.newChat(programTag, true, lessonType)
      store.resetLessonStat()
    }
  }

  return (
    <div
      className="fixed left-0 top-0 z-30 flex h-[100dvh] w-full flex-col bg-white"
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <AnimatePresence>
        <div
          className={cn('h-full', isHelpLesson ? '' : 'mt-100 msm:mt-[25%]')}
        >
          <p className="mx-auto text-center text-44 font-extrabold text-black">
            {isHelpLesson ? (
              <FormattedMessage id="Great job" />
            ) : (
              <FormattedMessage
                id="Great job student"
                values={{ name: _state.statistics.username }}
              />
            )}
          </p>
          <p className="mx-32 mt-16 text-center text-14 text-gray4">
            <FormattedMessage id="Tips for you to practice" />
          </p>
          <div className="mx-16 mt-32 flex h-fit min-h-136 gap-10">
            {lessonMetricCards.map((el: IMetricCard) => {
              const key = el.metricKey
              const getMetricValue = (): number | '?' => {
                if (metrics === null) return '?'

                if (
                  key === MetricKeysEnum.UniqueWordsChat ||
                  key === MetricKeysEnum.ErrorsChat
                ) {
                  return (
                    getValueByIndex(metrics[`${key}${programTag}`], 0)?.value ??
                    '?'
                  )
                }

                return getValueByIndex(metrics[key], 0)?.value ?? '?'
              }

              return (
                <MetricCard key={key} value={getMetricValue()} element={el} />
              )
            })}
          </div>
        </div>
      </AnimatePresence>
      <BottomButtons
        firstButtonProps={{
          handler: () => {
            handleRepeat()
          },
          textId: 'Repeat the lesson',
          bg: 'transparent',
          visible: !isHelpLesson,
        }}
        secondButtonProps={{
          handler: () => {
            handleContinue()
          },
          textId: 'Continue',
          bg: 'blue-gradient',
          visible: true,
          withIcon: !isHelpLesson,
        }}
      />
      {isHelpLesson && (
        <div className="absolute bottom-0 left-0 max-h-[50%] w-full">
          <img
            src="/images/lessonStatisticsFooter.png"
            className="h-auto max-h-[50%] w-full"
          />
          <p className="absolute inset-0 mx-32 flex items-center justify-center text-center font-nunito-7-condensed text-20">
            <FormattedMessage
              id="finish.firstStep.stat"
              values={{ br: <br />, span: <span /> }}
            />
          </p>
        </div>
      )}
    </div>
  )
}
