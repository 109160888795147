import React, { AnimationEvent, useRef } from 'react'
import { cn } from '../../lib/utils.ts'
import { isWord, splitToWordsAndSymbols } from '../../lib/stringUtils.ts'
import { classed } from '@tw-classed/react'

interface ClickableWordsProps {
  children: string
  className?: string
  selectedWords: readonly { word: string; dark: boolean }[]
  onClick?: (word: string, add: boolean) => void
  delay?: number
  animateSpeed?: 'slow' | 'fast'
  disabled: boolean
  onLastDisplayEnd?: () => void
  contexts?: readonly string[]
}

const WordContainer = classed.span('', {
  variants: {
    animate: {
      true: 'inline-block size-0 animate-appShow opacity-0 fill-mode-forwards',
      false: 'fill-mode-forwards',
    },
    state: {
      dark: 'rounded-6 bg-blue-light text-black',
      light: 'rounded-6 bg-orange-dark text-default',
      selected: 'rounded-6 bg-purple-light',
    },
  },
})

interface WordGroupProps {
  children: React.ReactNode
}

const WordGroup = ({ children }: WordGroupProps) => {
  return (
    <span className="inline-block" id="vocabulary-words-wrapper">
      {children}
    </span>
  )
}

interface GroupedWords {
  words: string[]
  isInContext: boolean
  startIndex: number
}

// Вспомогательная функция для получения контекстных слов
function getContextWords(contexts: readonly string[]): string[] {
  return contexts.flatMap((context) =>
    splitToWordsAndSymbols(context)
      .filter(isWord)
      .map((word) => word.toLowerCase()),
  )
}

// Вспомогательная функция для нахождения позиций контекстных слов
function findContextPositions(
  words: string[],
  contextWords: string[],
): number[] {
  return words.reduce<number[]>((positions, word, index) => {
    if (isWord(word) && contextWords.includes(word.toLowerCase())) {
      positions.push(index)
    }
    return positions
  }, [])
}

// Вспомогательная функция для обработки группы слов
function processWordGroup(
  words: string[],
  currentPos: number,
  nextPos: number,
  currentGroup: string[],
  startIndex: number,
): GroupedWords {
  const groupWords =
    currentGroup.length > 0
      ? [...currentGroup, ...words.slice(currentPos, nextPos)]
      : words.slice(currentPos, nextPos)

  return {
    words: groupWords,
    isInContext: true,
    startIndex: startIndex || currentPos,
  }
}

// Вспомогательная функция для добавления начального текста
function addInitialTextGroup(
  groups: GroupedWords[],
  words: string[],
  firstContextPos: number,
): void {
  if (firstContextPos > 0) {
    groups.push({
      words: words.slice(0, firstContextPos),
      isInContext: false,
      startIndex: 0,
    })
  }
}

// Вспомогательная функция для добавления оставшегося текста
function addRemainingTextGroup(
  groups: GroupedWords[],
  words: string[],
  nextPos: number,
): void {
  if (nextPos < words.length) {
    groups.push({
      words: words.slice(nextPos),
      isInContext: false,
      startIndex: nextPos,
    })
  }
}

function groupWordsByContext(
  words: string[],
  contexts?: readonly string[],
): GroupedWords[] {
  if (!contexts?.length) {
    return [{ words, isInContext: false, startIndex: 0 }]
  }

  const groups: GroupedWords[] = []
  let currentGroup: string[] = []
  let startIndex = 0

  // Получаем контекстные слова
  const contextWords = getContextWords(contexts)

  // Находим позиции контекстных слов
  const contextPositions = findContextPositions(words, contextWords)

  if (contextPositions.length === 0) {
    return [{ words, isInContext: false, startIndex: 0 }]
  }

  // Обрабатываем текст до первого контекстного слова
  addInitialTextGroup(groups, words, contextPositions[0])

  // Обрабатываем группы слов между контекстными словами
  for (let i = 0; i < contextPositions.length; i++) {
    const currentPos = contextPositions[i]
    const nextPos =
      i < contextPositions.length - 1 ? contextPositions[i + 1] : words.length

    // Максимальное расстояние между словами в группе
    const MAX_GROUP_DISTANCE = 15
    const shouldGroupWithNext =
      i < contextPositions.length - 1 &&
      contextPositions[i + 1] - currentPos <= MAX_GROUP_DISTANCE

    if (shouldGroupWithNext) {
      // Продолжаем текущую группу
      if (currentGroup.length === 0) {
        startIndex = currentPos
      }
      currentGroup.push(...words.slice(currentPos, contextPositions[i + 1]))
    } else {
      // Завершаем текущую группу
      groups.push(
        processWordGroup(words, currentPos, nextPos, currentGroup, startIndex),
      )
      currentGroup = []
    }

    // Добавляем оставшийся текст после последнего контекстного слова
    if (i === contextPositions.length - 1) {
      addRemainingTextGroup(groups, words, nextPos)
    }
  }

  return groups
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function Word(props: {
  currentIndex: number | null
  setCurrentIndex: (index: number | null) => void
  word: string
  index: number
  selectedWords: readonly { word: string; dark: boolean }[]
  onClick?: (word: string, add: boolean) => void
  animateSpeed?: 'slow' | 'fast'
  delay?: number
  onLastDisplayEnd?: () => void
}) {
  const animate = !!props.animateSpeed
  const style = props.animateSpeed
    ? {
        animationDelay: `${
          (props.delay ?? 0) +
          props.index * (props.animateSpeed == 'fast' ? 10 : 30)
        }ms`,
      }
    : {}

  const handleAnimationEnd = (event: AnimationEvent<HTMLSpanElement>) => {
    event.currentTarget.classList.remove('inline-block')
    props.onLastDisplayEnd?.()
  }
  if (!isWord(props.word)) {
    return (
      <WordContainer
        animate={animate}
        style={style}
        key={props.index}
        onAnimationEnd={props.animateSpeed ? handleAnimationEnd : undefined}
      >
        {props.word}
      </WordContainer>
    )
  }

  const selectedWord = props.selectedWords.find(
    (x) => x.word.toLowerCase() == props.word.toLowerCase(),
  )
  const isCurrent = props.index == props.currentIndex
  const state = isCurrent
    ? 'selected'
    : selectedWord == undefined
    ? undefined
    : props.word.toLowerCase() === selectedWord.word.toLowerCase()
    ? 'dark'
    : 'light'

  const handleClick = () => {
    if (props.onClick) {
      props.onClick(props.word, !selectedWord)
    }
  }

  return (
    <WordContainer
      animate={animate}
      style={style}
      state={state}
      onAnimationEnd={props.animateSpeed ? handleAnimationEnd : undefined}
      onClick={handleClick}
      id={'word-' + props.word}
    >
      {props.word}
    </WordContainer>
  )
}

function generateSortedRandomDelays(length: number) {
  const minDelay = 10
  const maxDelayIntervalFrom = 500
  const maxDelayIntervalTo = 2000
  const normalized = Math.min(1, Math.max(0, length / 100))
  const N = Math.round(
    maxDelayIntervalFrom +
      (maxDelayIntervalTo - maxDelayIntervalFrom) * normalized,
  )
  const randomDelays: number[] = []

  for (let i = 0; i < length; i++) {
    const randomNumber =
      Math.floor(Math.random() * (N - minDelay + 1)) + minDelay
    randomDelays.push(randomNumber)
  }

  randomDelays.sort((a, b) => a - b)

  return randomDelays
}

export const ClickableWords = React.memo((props: ClickableWordsProps) => {
  const words = splitToWordsAndSymbols(props.children)
  const [currentIndex, setCurrentIndex] = React.useState<number | null>(null)
  const delaysRef = useRef(generateSortedRandomDelays(words.length))

  if (props.disabled) {
    return props.children
  }

  if (!props.contexts?.length) {
    return (
      <div
        className={cn(
          'size-fit select-none whitespace-pre-wrap',
          props.className,
        )}
      >
        {words.map((word, index) => (
          <Word
            key={index}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            animateSpeed={props.animateSpeed}
            onClick={props.onClick}
            word={word}
            delay={(props.delay ?? 0) + delaysRef.current[index]}
            index={index}
            onLastDisplayEnd={
              index === words.length - 1 ? props.onLastDisplayEnd : undefined
            }
            selectedWords={props.selectedWords}
          />
        ))}
      </div>
    )
  }

  const wordGroups = groupWordsByContext(words, props.contexts)

  return (
    <div
      className={cn(
        'size-fit select-none whitespace-pre-wrap',
        props.className,
      )}
    >
      {wordGroups.map((group, groupIndex) => {
        const groupContent = group.words.map((word, i) => {
          const globalIndex = group.startIndex + i
          return (
            <Word
              key={globalIndex}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              animateSpeed={props.animateSpeed}
              onClick={props.onClick}
              word={word}
              delay={(props.delay ?? 0) + delaysRef.current[globalIndex]}
              index={globalIndex}
              onLastDisplayEnd={
                globalIndex === words.length - 1
                  ? props.onLastDisplayEnd
                  : undefined
              }
              selectedWords={props.selectedWords}
            />
          )
        })

        return group.isInContext ? (
          <WordGroup key={groupIndex}>{groupContent}</WordGroup>
        ) : (
          <React.Fragment key={groupIndex}>{groupContent}</React.Fragment>
        )
      })}
    </div>
  )
})
ClickableWords.displayName = 'ClickableWords'
