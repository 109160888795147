import { proxy } from 'valtio'
import { ApiOnboardingInfo, ChatApi } from '../../shared/api/chatApi.ts'
import {
  delay,
  getLanguageLevelByKey,
  getLast,
  getValueByKey,
  typedIncludes,
} from '../../shared/lib/utils.ts'
import { AuthStore } from '../auth/authStore.ts'
import { MarketingEvents } from '../../shared/api/authApi.ts'
import { setLoading } from '../../shared/lib/storeUtils.ts'
import { SubscriptionService } from '../subscriptions/subscriptionService.ts'
import {
  LanguageLevelKeys,
  OnboardingStep,
  onboardingSteps,
  SexKeys,
  ProfessionKeys,
  ImproveKeys,
  DurationKeys,
  GoalKeys,
  AgeKeys,
  InterestKeys,
} from '../../shared/data/onboardingSteps.ts'
import { OnboardingData } from './onboardingData.ts'
import { AppSessionStorage, Level } from '../../shared/lib/appSessionStorage.ts'
import { LangLevels } from '../../shared/data/onboardingSteps.ts'

const minInterestsCount = 3
const maxInterestsCount = 5
const stepTypeEvents = {
  goal: 'purpose_of_study_sent',
  profession: 'profession_sent',
  interests: 'hobby_sent',
  level: 'user_english_level_sent',
} satisfies Record<string, MarketingEvents | undefined>

export interface OnboardingInfo {
  name?: string
  age?: string
  goal?: string
  sex?: string
  improves: string[]
  duration?: string
  profession?: string
  interests: string[]
  languageLevel?: string | LanguageLevelKeys
}
export const InfoKeyWithArrayValue = ['improves', 'interests'] as const
export type InfoKeyWithArrayValue = (typeof InfoKeyWithArrayValue)[number]

interface OnboardingState {
  skipDemo: boolean
  goToDemo: boolean
  goalNumber?: 1 | 2 | 3 | 4 | 5
  level?: Level
  editOther: boolean
  loading: boolean
  completed: boolean
  hasSubscription: boolean
  currentStep: number
  info: OnboardingInfo
  canGoNext: boolean
  canGoPrev: boolean
  started: boolean
  stepType: OnboardingStep
  hideBackButton: boolean
  userLastStep: number
  isForward: boolean
}

const defaultState: OnboardingState = {
  skipDemo: false,
  goToDemo: false,
  get stepType() {
    return onboardingSteps[this.currentStep].step
  },
  get hideBackButton() {
    return ['hello', 'program', 'askdemo'].includes(this.stepType)
  },
  completed: false,
  loading: false,
  hasSubscription: false,
  editOther: false,
  started: false,
  currentStep: 0,
  userLastStep: -1,
  isForward: false,
  info: {
    interests: [],
    improves: [],
    duration: DurationKeys.Fifteen,
  },

  get canGoNext() {
    if (this.editOther) {
      return false
    }

    switch (this.stepType) {
      case 'improves':
        return this.info.improves.length > 0
      case 'duration':
        return !!this.info.duration
      case 'sex':
        return !!this.info.sex
      case 'age':
        return !!this.info.age
      case 'profession':
        return !!this.info.profession
      case 'interests':
        return this.info.interests.length >= minInterestsCount
      case 'level':
        return !!this.info.languageLevel
      case 'goal':
        return !!this.info.goal
      default:
        return true
    }
  },
  get canGoPrev() {
    if (this.editOther) {
      return false
    }
    return this.currentStep > 0
  },
}

export class OnboardingStore {
  state: OnboardingState

  constructor(
    private chatApi: ChatApi,
    private authStore: AuthStore,
    private subscriptionService: SubscriptionService,
    public data: OnboardingData,
    public appSessionStorage: AppSessionStorage,
    initialStep: OnboardingStep,
  ) {
    this.state = proxy(defaultState)
    this.setStep(initialStep)
    this.loadStateFromSession()

    window.skipOnboarding = () => {
      this.skipOnboarding()
    }
  }

  loadFromApiInfo(info: ApiOnboardingInfo) {
    this.state.info.languageLevel = info.level
    this.state.info.age = info.age

    if (info.goal) {
      const goalString = String(info.goal)
      const goalItem = this.data.goal.find((item) => item[1] === goalString)
      if (goalItem) {
        this.state.info.goal = goalItem[2].toString() as GoalKeys
      } else {
        this.state.info.goal = info.goal as GoalKeys
      }
    } else {
      this.state.info.goal = info.goal as GoalKeys
    }

    this.state.info.sex = info.sex
    this.state.info.profession = info.profession
    this.state.info.interests = [...info.interests]
    this.state.info.improves = [...info.improves]
    this.state.info.duration = info.duration
  }

  getProfileData() {
    const keys: (keyof OnboardingInfo)[] = [
      'name',
      'sex',
      'age',
      'profession',
      'interests',
      'languageLevel',
      'goal',
      'improves',
      'duration',
    ]
    // eslint-disable-next-line sonarjs/cognitive-complexity
    return keys.map((key) => {
      const value = this.state.info[key]
      if (!value) {
        return { icon: '', text: '', value: '', key }
      }

      if (key === 'name') {
        return {
          icon: '👤',
          text: value as string,
          value,
          key,
        }
      }

      if (Array.isArray(value)) {
        const texts = value.map((itemValue) => {
          // For Arrays
          if (key === 'improves' || key === 'interests') {
            const dataItem = this.data[key].find(
              (i) => i[2].toString() === itemValue,
            )
            return dataItem ? dataItem[1] : itemValue
          }
          return itemValue
        })
        return {
          icon: this.getIcon(key, value[0]),
          text: texts.join(', '),
          value: value,
          key,
        }
      } else {
        // For strings
        if (
          key === 'sex' ||
          key === 'age' ||
          key === 'profession' ||
          key === 'languageLevel' ||
          key === 'goal' ||
          key === 'duration'
        ) {
          const dataItem = this.data[key].find((i) => i[2].toString() === value)
          return {
            icon: this.getIcon(key, value),
            text: dataItem ? dataItem[1] : value,
            value,
            key,
          }
        }

        return {
          icon: this.getIcon(key, value),
          text: value,
          value,
          key,
        }
      }
    })
  }

  getIcon(key: keyof OnboardingInfo, value: string) {
    if (key === 'name') {
      return '👤'
    }
    if (key in this.data) {
      const icon = this.data[key as keyof OnboardingData].find(
        (i) => i[2].toString() === value,
      )
      return icon ? icon[0] : ''
    }

    return ''
  }

  async load() {
    if (!this.authStore.isLogin()) {
      await this.authStore.registerAnon('A2')
    } else if (!this.authStore.state.isAnon) {
      const [program, subscription] = await setLoading(this.state, () =>
        Promise.all([
          this.chatApi.userPrograms(),
          this.subscriptionService.getActiveSubscription(),
        ]),
      )
      this.state.completed = program.length > 0
      this.state.hasSubscription = !!subscription
    }
  }

  setEditOther(value: boolean) {
    this.state.editOther = value
  }

  setInfo(key: keyof OnboardingInfo, value: string) {
    this.state.editOther = false
    if (typedIncludes(InfoKeyWithArrayValue, key)) {
      this.toggleItem(key, value, false)
    } else {
      this.state.info[key] = value
    }

    switch (key) {
      case 'goal':
        this.authStore.trackOnbMetrika(`goal_${value}`)
        break
      case 'profession':
        this.authStore.trackOnbMetrika(`profession_${value}`)
        break
    }
  }

  updateOther(key: keyof OnboardingInfo, value: string) {
    this.state.editOther = false
    const keyData = {
      goal: [],
      profession: [],
      interests: [],
      sex: [],
      age: [],
      name: [],
      languageLevel: [],
      improves: [],
      duration: [],
    }
    const data = keyData[key] as [string, string, string][]
    if (data.length > 0) {
      const last = getLast(data)
      if (last) {
        if (typedIncludes(InfoKeyWithArrayValue, key)) {
          this.toggleItem(key, last[1], true)
        }
        last[1] = value
        last[2] = value
      }
      this.setInfo(key, value)
    }
  }

  toggleItem(key: InfoKeyWithArrayValue, item: string, remove = false) {
    const maxCount =
      key == 'interests' ? maxInterestsCount : this.data.improves.length
    const index = this.state.info[key].indexOf(item)
    if (index === -1 && !remove) {
      if (this.state.info[key].length < maxCount) {
        this.state.info[key].push(item)
      }
    } else if (index !== -1) {
      this.state.info[key].splice(index, 1)
    }
  }

  skipDemo() {
    this.state.skipDemo = true
    this.changeStep(true)
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  changeStep(next: boolean) {
    this.state.isForward = next
    if (next) {
      //   if (!this.state.skipDemo && this.state.stepType == 'askdemo') {
      //   if (!this.state.skipDemo) {
      //     this.state.goToDemo = true
      //   }
      // if (this.state.stepType == 'name') {
      //   void this.updateAccount()
      // }
      if (this.state.stepType == getLast(onboardingSteps)?.step) {
        this.state.completed = true
      } else if (
        this.state.canGoNext &&
        this.state.currentStep < onboardingSteps.length - 1
      ) {
        const step = this.state.stepType
        const event = getValueByKey(stepTypeEvents, step)
        if (event) {
          this.authStore.trackEvent(event)
        }
        this.state.currentStep += 1
      }
      if (this.state.stepType == 'generation' && !this.state.goToDemo) {
        void this.createProgram()
      }
    } else {
      if (this.state.canGoPrev) {
        this.state.currentStep -= 1
      }
    }

    this.state.started = true
    this.state.editOther = false
  }

  resetGoToDemo() {
    this.state.goToDemo = false
  }

  setStep(step: OnboardingStep) {
    this.state.currentStep = onboardingSteps.findIndex(
      (onboardingStep) => onboardingStep.step === step,
    )
    if (step == 'generation') {
      void this.createProgram()
    }
  }

  async createProgram() {
    // await this.updateAccount()
    await delay(10000)
    if (this.state.stepType != 'generation') {
      return
    }
    this.changeStep(true)
  }

  getApiOnboardingInfo(): ApiOnboardingInfo {
    return {
      goal: this.state.info.goal as GoalKeys,
      profession: this.state.info.profession as ProfessionKeys,
      interests: this.state.info.interests as InterestKeys[],
      sex: this.state.info.sex as SexKeys,
      age: this.state.info.age as AgeKeys,
      improves: this.state.info.improves as ImproveKeys[],
      duration: this.state.info.duration as DurationKeys,
      level: this.state.info.languageLevel ?? '',
    }
  }

  skipOnboarding() {
    this.state.info.name = 'Ivan'
    this.state.level = 'A2'
    // await this.updateAccount()
    this.state.skipDemo = true
    this.state.completed = true
  }

  async updateAccount() {
    if (!this.state.level) {
      return
    }

    await this.chatApi.updateAccount({
      name: this.state.info.name ? this.state.info.name : 'Студент',
      level: this.state.level,
      onboarding_info: this.getApiOnboardingInfo(),
    })
  }

  setGoalNumber(number: 1 | 2 | 3 | 4 | 5) {
    this.state.goalNumber = number
  }

  setLevel(level: LangLevels, detailedLevel: LanguageLevelKeys) {
    this.state.level = level
    this.appSessionStorage.set('level', detailedLevel)
    this.state.info.languageLevel = detailedLevel

    this.authStore.trackOnbMetrika(`level_${level}`)
  }

  updateUserLastStep() {
    this.state.userLastStep = Math.max(
      this.state.userLastStep,
      this.state.currentStep,
    )
  }

  updateSession() {
    this.appSessionStorage.set('onboarding', {
      currentStep: this.state.currentStep,
      started: this.state.started,
      userLastStep: this.state.userLastStep,
      isForward: this.state.isForward,
      info: this.state.info,
      completed: this.state.completed,
      goalNumber: this.state.goalNumber,
    })
  }

  loadStateFromSession() {
    const sessionValue = this.appSessionStorage.get('onboarding') ?? {}
    const sessionLevel = this.appSessionStorage.get('level') ?? undefined

    this.state.started = sessionValue.started ?? this.state.started
    this.state.isForward = sessionValue.isForward ?? this.state.isForward
    this.state.currentStep = sessionValue.currentStep ?? this.state.currentStep
    this.state.userLastStep =
      sessionValue.userLastStep ?? this.state.userLastStep
    this.state.info = sessionValue.info ?? this.state.info
    this.state.completed = sessionValue.completed ?? this.state.completed
    this.state.goalNumber = sessionValue.goalNumber
    this.state.level = sessionLevel
      ? getLanguageLevelByKey(sessionLevel)
      : undefined
  }
}
