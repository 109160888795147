import { ProfileView } from '../features/profile/ProfileView.tsx'
import { IntlProvider } from 'react-intl'
import { messages } from '../shared/translations/messages.ts'
import { useOnboardingData } from '../features/onboarding/onboardingData.ts'
import { useSnapshot } from 'valtio/react'
import { useAppModule } from '../features/appContext.ts'

export function ProfilePage() {
  const appModule = useAppModule()
  const state = useSnapshot(appModule.appStore.state)
  return (
    <IntlProvider locale={state.language} messages={messages[state.language]}>
      <IntlWrapper />
    </IntlProvider>
  )
}

function IntlWrapper() {
  const onboardingData = useOnboardingData()
  const state = useSnapshot(useAppModule().appStore.state)
  return (
    <IntlProvider locale={state.language} messages={messages[state.language]}>
      <ProfileView onboardingData={onboardingData} />
    </IntlProvider>
  )
}
