/* eslint-disable boundaries/no-ignored */
import InstructionImage from './instruction.svg?react'
import LessonCompleteImage from './lesson-complete.svg?react'
import ProgramCreatingImage from './program-creating.svg?react'
import DiscountMonthsImage from './discount-months.svg?react'
import DiscountYearImage from './discount-year.svg?react'
import PaymentsImage from './payments.svg?react'
import RuImage from './ru.svg?react'
import GbImage from './gb.svg?react'
import CorrectImage from './correct.svg?react'
import WrongImage from './wrong.svg?react'
import LogoImg from './logo.svg?react'
import TrImage from './tr.svg?react'
import ViImage from './vi.svg?react'

import { useState } from 'react'
import { cn } from '../../lib/utils.ts'

export {
  CorrectImage,
  WrongImage,
  InstructionImage,
  LessonCompleteImage,
  ProgramCreatingImage,
  DiscountYearImage,
  DiscountMonthsImage,
  PaymentsImage,
  RuImage,
  GbImage,
  TrImage,
  ViImage,
  LogoImg,
}

export const ImageHideBeforeLoad = (props: {
  src: string
  className?: string
  id?: string
  width?: number
  height?: number
  onLoaded?: () => void
}) => {
  const [loaded, setLoaded] = useState(false)
  return (
    <img
      id={props.id}
      className={cn(
        'transition-opacity',
        loaded ? 'opacity-100' : 'opacity-0',
        props.className,
      )}
      width={props.width}
      height={props.height}
      alt="Image"
      src={props.src}
      onLoad={() => {
        setLoaded(true)
        props.onLoaded?.()
      }}
      onError={() => {
        setLoaded(true)
        props.onLoaded?.()
      }}
    />
  )
}
