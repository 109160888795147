import { useLazyRef } from '../shared/lib/hooks.ts'
import { useAppModule } from '../features/appContext.ts'
import { SubscriptionView } from '../features/onboarding/subscriptionView.tsx'
import { BuyStoreContext, Country } from '../features/onboarding/buyStore.ts'
import { Toasts } from '../features/toasts/toasts.tsx'
import { useMount } from '../shared/lib/utils.ts'
import { IntlProvider } from 'react-intl'
import { messages } from '../shared/translations/messages.ts'
import { OnboardingContext } from '../features/onboarding/OnboardingView.tsx'
import { OnboardingStore } from '../features/onboarding/OnboardingStore.ts'
import { useOnboardingData } from '../features/onboarding/onboardingData.ts'
import { useSnapshot } from 'valtio/react'

export function SubscriptionsPage(props: { country: Country }) {
  const appModule = useAppModule()
  const appState = useSnapshot(appModule.appStore.state)
  const buyStore = useLazyRef(() => appModule.buyStore(props.country)).current
  const authStore = useAppModule().authStore

  const data = useOnboardingData()
  const storeRef = useLazyRef(
    () =>
      new OnboardingStore(
        appModule.chatsApi,
        appModule.authStore,
        appModule.subscriptionService(),
        data,
        appModule.appSessionStorage,
        'hello',
      ),
  )

  useMount(() => {
    authStore.trackOnbMetrika('onboarding_passed')
    authStore.trackOnbMetrika('subscriptions')
    authStore.trackMetrika('demo_lesson_start')
    authStore.trackMetrika('demo2_subscription_visit')
    authStore.trackEvent('subscribtion_page_load')
    void authStore.saveMarkData()
  })

  return (
    <IntlProvider
      locale={appState.language}
      messages={messages[appState.language]}
    >
      <OnboardingContext.Provider value={storeRef.current}>
        <BuyStoreContext.Provider value={buyStore}>
          <SubscriptionView />
          <Toasts />
        </BuyStoreContext.Provider>
      </OnboardingContext.Provider>
    </IntlProvider>
  )
}
